import _ from 'lodash';
import { connect } from 'react-redux';
import { showErrors } from '../modules/general';
import { bindActionCreators } from 'redux'

const mapStateToProps = state => {
	const permissions = _.get(state.auth.authUser, ['permissions'], []);
	const isAdmin = _.includes(permissions, 'isAdmin');
	const accessToken = _.get(state.auth, 'accessToken');

	return {
		authUser : state.auth.authUser,
		permissions : permissions,
		accessToken,
		isAdmin,
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	showErrors,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
);
