import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import goodJobSvg from '../../images/icons/goodJob.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
	goodJobIcon: {
		display : 'inline-block',
		width : 50,
		height : 50,
		backgroundImage: `url('${goodJobSvg}')`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		verticalAlign: 'middle',
	},
	title : {
		fontSize : 30,
		display: 'inline-block',
		verticalAlign: 'middle',
		paddingLeft: 5,
		paddingTop: 5,
	},
	inner : {
		padding : '10px 20px',
	}
};

const typeMap = {
	flashSale : 'Sale',
	comingSoon : 'Coming Soon',
	grandOpening : 'Open Now',
};

export default class AreYouSureDialog extends Component {
	render() {
		const {
			open, onClose,
			totalCustomers,
			currentType,
			message,
		} = this.props;

		return (
			<div>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					onClose={onClose}
				>
					<div style={styles.inner}>
						<DialogTitle>
							Please double check everything before sending
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<div style={{fontSize : '120%'}}>
									<div style={{borderBottom : '1px solid #ddd', marginBottom : 10, paddingBottom : 10}}>
										<strong>{totalCustomers}</strong> total customers will receive this notification
									</div>
									<div>
										<strong>{typeMap[currentType]}</strong>
									</div>
									<div>
										{message}
									</div>
								</div>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={this.props.onClose}
								variant={"outlined"}
							>
								Cancel
							</Button>

							<Button
								onClick={this.props.handleSave}
								variant={"contained"}
								color="primary"
							>
								SEND!
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}
}

AreYouSureDialog.propTypes = {
	open : PropTypes.bool,
	onClose : PropTypes.func,
	totalCustomers : PropTypes.number,
	message : PropTypes.string,
	currentType : PropTypes.string,
	handleSave : PropTypes.func.isRequired,
};

AreYouSureDialog.defaultProps = {
	processingText : 'Processing...',
	successText : 'Save was successful'
};
