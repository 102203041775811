import _ from 'lodash';
import moment from 'moment';
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// Components
import FilterHeader from "../../components/table/FilterHeader";
import EnhancedTableHead from '../../components/table/EnhancedTableHead';

// Helpers
import {isFiltered, sortTable} from '../../libs/tableUtilities';
import { getGlobalClasses } from '../../themes';

// Containers
import AuthUserContainer from '../../containers/AuthUser';
import NotificationContainer from '../../containers/Notification';

const styles = (theme) => {
	return {
		...getGlobalClasses(theme),
		table : {
			width: '100%',
			tableLayout: 'auto',
		},
	};
};

const notificationColumns = [
	{ id: 'type', label: 'Type' },
	{ id: 'station', label: 'Station' },
	{ id: 'message', label: 'Message'  },
	{ id: 'customerCount', label: 'Customers' },
	{ id: 'createdSort',  label: 'Sent By',},
	{ id: 'status',  label: 'Status',},
	{ id: 'edit', label : 'View', isEdit : true }
];

class NotificationList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			order: 'asc',
			orderBy: 'firstName',
			filter: '',
		};
	}

	componentDidMount () {
		if (!this.props.notifications.length) {
			this.props.searchNotifications();
		}
	}

	handleSort = (orderBy) => {
		let order = 'desc';
		if (this.state.orderBy === orderBy && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleEdit = (notificationId) => {
		this.props.history.push(`/notification/update/${notificationId}`);
	};

	handleFilter = (event) => {
		this.setState({ filter : event.target.value });
	};

	render() {
		const {
			classes,
			notifications,
			userMap,
			stationMap,
		} = this.props;

		const {
			order,
			orderBy,
			filter,
		} = this.state;

		if (!notifications) {
			return (<div>Fetching notifications...</div>);
		}

		let notificationRows = notifications.reduce((result, notification) => {

			const {
				notificationId,
				type,
				message,
				creatorUserId, dateCreated,
				destinationStationId,
				customerIds,
			} = notification;

			const dateCreatedFormatted = moment(dateCreated).format('MMM Do, YYYY h:mm a');

			const createdByUser = userMap[creatorUserId] || { firstName : 'Missing'};
			const station = stationMap[destinationStationId] || { stationName : 'Missing'};

			const customerCount = customerIds.length;

			const typeMap = {
				flashSale : 'Sale',
				comingSoon : 'Coming Soon',
				grandOpening : 'Now Open',
			};

			const created = (<Fragment>
				<div>{createdByUser.firstName} {createdByUser.lastName}</div>
				<div style={{fontSize : '90%', color : '#666'}}>@ {dateCreatedFormatted}</div>
			</Fragment>);

			const { stationName, number} = station;
			let label  = `${number} - ${stationName}`;
			if (!number) { label  = `${stationName}`; }

			const row = {
				notificationId,
				type,
				typeFormatted : typeMap[type] || 'missing',
				message,
				station : label,
				created,
				createdSort : dateCreated,
				customerCount,
				dateCreatedFormatted,
				status : "TODO"
			};

			if (isFiltered({filter, row, fields : ['type', 'message']})) {
				return result;
			}

			result.push(row);
			return result;
		}, []);

		notificationRows = sortTable({ rows : notificationRows, order, orderBy});

		return (<Fragment>
				<FilterHeader
					value={this.state.filter}
					onChange={this.handleFilter}
					label={'Notifications'}
					buttonText="Send New Notification"
					url="/notification/add"
					canMakeUpdates={true}
				/>
				<Paper className={classes.globalMainPaper}>
					<Table size='small' className={classes.table}>
						<EnhancedTableHead
							columns={notificationColumns}
							order={order}
							orderBy={orderBy}
							onRequestSort={this.handleSort}
						/>
						<TableBody>
							{notificationRows.map(row => {
								return (
									<TableRow
										hover
										className={classes.row}
										key={row.notificationId}
									>
										<TableCell>{row.typeFormatted}</TableCell>
										<TableCell>{row.station}</TableCell>
										<TableCell>{row.message}</TableCell>
										<TableCell>{row.customerCount}</TableCell>
										<TableCell>{row.created}</TableCell>
										<TableCell>{row.status}</TableCell>
										<TableCell align={'center'} style={{whiteSpace:'nowrap'}}>
											<Button
												className={classes.button}
												onClick={() => this.handleEdit(row.notificationId)}
											>
												View
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Paper>
			</Fragment>
		);
	}
}

NotificationList.propTypes = {
	classes: PropTypes.object.isRequired,

	searchNotifications: PropTypes.func.isRequired,
	notifications : PropTypes.array,
	userMap : PropTypes.object,
	stationMap : PropTypes.object,

	order: PropTypes.string,
	orderBy: PropTypes.string,
	history : PropTypes.object,
	theme: PropTypes.object.isRequired,
};

export default _.flow([
	withStyles(styles, { withTheme: true }),
	NotificationContainer,
	AuthUserContainer,
])(NotificationList);