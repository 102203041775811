import React, { Component, Fragment } from "react";
import _ from 'lodash';

import {
	withGoogleMap,
	GoogleMap,
	withScriptjs,
	Marker,
	Circle
} from "react-google-maps";
import PropTypes from "prop-types";
//import _ from "lodash";

import greenMarker from '../../images/mapMarkers/greenMapMarker.png';
import blueMarker from '../../images/mapMarkers/blueMapMarker.png';

function getMeters(i) {
	return i*1609.344;
}

class StationMap extends Component {
	constructor(props) {
		super(props);

		const { circleCenter } = this.props;

		this.state = {
			isDragging : false,
			draggingCircleCenter : circleCenter,
		};
	}

	/*
	componentDidUpdate(prevProps, prevState, snapShot) {
		const { center } = this.props;

		if (_.isObject(prevProps.center) && _.isObject(center)) {
			if (
				prevProps.center.lat !== center.lat
				|| prevProps.center.lng !== center.lng
			) {
				console.log("NEW CENTER", prevProps.center, center);
				this.setState({
					centerLatitude: center.lat,
					centerLongitude: center.lng,
				});
			}
		}
	}
	*/

	onMapDragEnd = () => {
		/*
		const latLng = this.mapRef.getCenter();
		this.props.onMapDragEnd({
			lat : latLng.lat(),
			lng : latLng.lng(),
		});
		 */
	};

	render () {
		const {
			radiusDistance, zoom,
			mapCenter,
			stations
		} = this.props;

		const { isDragging, draggingCircleCenter } = this.state;

		const circleCenter = (isDragging) ?  draggingCircleCenter : this.props.circleCenter;

		let open = [];
		const comingSoon = [];
		let filteredAndSortedStations = [];
		stations.forEach(station => {
			const { location, name, stationName, number, status} = station;

			if (!number.match(/^GM/) && !name.match(/G&M/) && !stationName.match(/G&M/) ) {
				return null;
			}

			if (!location || !location.longitude || !location.latitude) {
				console.log(`Missing long / lat for ${number} - ${name}`)
				return null;
			}

			station.latitude = location.latitude;
			station.longitude = location.longitude;

			if (status === 'coming_soon') {
				comingSoon.push(station)
			} else {
				open.push(station);
			}
			filteredAndSortedStations.push(station);
		});

		console.log(`TOTAL:${filteredAndSortedStations.length}, OPEN:${open.length}, ${comingSoon.length}`);

		_.sortBy(comingSoon, 'name').forEach(station => {
			const {name, stationName, address} = station;
			console.log(`Name: ${name} Public Name:${stationName}, Address:${address}`);
		});

		return (<Fragment>
			<GoogleMap
				defaultZoom={zoom}
				center={mapCenter}
				onDragEnd ={this.onMapDragEnd}
				ref={(ref) => {
					this.mapRef = ref;
				}}
			>
				{_.orderBy(filteredAndSortedStations, 'latitude', 'desc').map(station => {
					const { stationId, status, location } = station;

					const iconUrl = (status === 'coming_soon') ? greenMarker : blueMarker;
					const scale = 0.3;
					const icon = {
						url: iconUrl,
						scaledSize : { width : 160 * scale, height : 192 * scale,  },
						// scaledSize: new google.maps.Size(50, 50), // scaled size
						// origin: new google.maps.Point(0,0), // origin
						// anchor: new google.maps.Point(0, 0) // anchor
					};

					return (
						<Marker
							key={stationId}
							position={{
								lat: parseFloat(location.latitude),
								lng: parseFloat(location.longitude),
							}}
							icon={icon}
							zIndex={5}
						/>
					);
				})}

			</GoogleMap>
		</Fragment>);
	}
}

StationMap.propTypes = {
	radiusDistance : PropTypes.number,
	stations : PropTypes.array,
	mapCenter : PropTypes.object,
	circleCenter : PropTypes.object,

	onDragStart : PropTypes.func.isRequired,
	onDragEnd : PropTypes.func.isRequired,
	onMapDragEnd : PropTypes.func.isRequired,
};

export default withScriptjs(withGoogleMap(StationMap));
