import { createMuiTheme } from '@material-ui/core/styles';
//import createPalette from '@material-ui/core/styles/palette';

import blue from '@material-ui/core/colors/blue';
//const stlBlue = '#137EC2';
const stlBlue = '#1289d4';
const stlOrange = '#E9791E';

export const theme = createMuiTheme({
	overrides: {
		MuiButton: {
			root : { // Name of the rule
				borderRadius : 100,
			},
		},
	},
	typography: {
		useNextVariants: true,
	},
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: stlBlue,
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			main: stlOrange,
			// dark: will be calculated from palette.secondary.main,
			//contrastText: '#ffcc00',
		},

		//primary: stlBlue,
	}
});

export const getGlobalClasses = (theme) => {
	return {
		globalMainHeadline : {
			textTransform : 'uppercase',
			color : '#666',
			fontSize : 20,
			fontWeight : 400,
			margin: '2px 0px 5px 0px',
			[theme.breakpoints.up('sm')]: {
				fontWeight: 300,
				color : '#999',
				fontSize : 30,
				margin: '10px 0px 10px 0px',
			},
			[theme.breakpoints.up('md')]: {
				fontSize : 40,
				margin: '10px 0px 10px 0px',
			},

			//paddingBottom : 20,
			//fontFamily: 'Roboto-Thin',
			//borderBottom: '1px solid #eee',
			letterSpacing: 1,
		},
		globalMainPaper: {
			width: '100%',
			//overflowX : 'auto',
			//minHeight : 500,
			textAlign: 'left',
			boxSizing: 'border-box',
			padding: 10,
			[theme.breakpoints.up('sm')]: {
				padding: '20px 30px 40px 30px',
			},
			borderRadius: 0,
			marginBottom : 20,
		},
	};
};

export const darkTheme = createMuiTheme({
	typography: {
		useNextVariants: true,
	},
	palette: {
		type: 'dark',
		primary: blue,
	},
});
