//import _ from 'lodash';
//import { createReducer } from './utility';

export const SAVING_EVENT = 'general/SAVING_EVENT';
export const SAVED_EVENT = 'general/SAVED_EVENT';
export const ERROR_EVENT = 'general/ERROR_EVENT';
export const CLEAR_ERROR = 'general/CLEAR_ERROR';
export const CLEAR_SAVING = 'general/CLEAR_SAVING';

const initialState = {};

//- Reducers
export default (state = initialState, action) => {
	switch (action.type) {
		case SAVING_EVENT:
			return {
				...state,
				savingMessage : action.savingMessage,
				savingStatus : 'saving'
			};

		case SAVED_EVENT:
			return {
				...state,
				savedMessage : action.savedMessage,
				onSuccessRedirectTo : action.onSuccessRedirectTo,
				savingStatus : 'done'
			};

		case CLEAR_SAVING:
			return {
				...state,
				savedMessage : undefined,
				onSuccessRedirectTo : undefined,
				savingStatus : undefined
			};

		case ERROR_EVENT:
			return {
				...state,
				errorMessages : action.errorMessages
			};
		case CLEAR_ERROR:
			return {
				...state,
				errorMessages : undefined,
			};
		default: return state;
	}
};

export const showErrors = (errors) => {
	return dispatch => {
		dispatch({
			type: ERROR_EVENT,
			errorMessages: errors,
		});
	};
};

export const clearError = () => {
	return dispatch => {
		dispatch({
			type: CLEAR_ERROR
		});
	};
};
export const clearSaving = () => {
	return dispatch => {
		dispatch({
			type: CLEAR_SAVING
		});
	};
};


