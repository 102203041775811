import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import AddButton from './AddButton';

import { getGlobalClasses } from '../../themes';

const styles = (theme) => ({
	...getGlobalClasses(theme),
	title : {
		display : 'flex',
		alignItems : 'flex-end'
	},
	searchBox: {
		fontSize: 14,
		marginLeft: 20,
		flex: 1,
		maxWidth : 200,
		paddingBottom : 5,
		display : 'none',
		[theme.breakpoints.up('sm')]: {
			display : 'block',
		}
	},
	headerContainer : {
		display : 'flex',
		marginBottom : 5,
		width : '100%',
		justifyContent : 'space-between',
		alignItems : 'center',
	},
	titleSearch : {
		display : 'flex',
		flexGrow : 1,
		alignItems : 'flex-end',
	},
	buttonAlign : {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		whiteSpace : 'nowrap',
	},
});

class FilterHeader extends Component {
	render () {
		const {
			label, value, classes, onChange,
			url, buttonText, canMakeUpdates,
		} = this.props;
		return (
			<Grid container>
				<Grid item xs={12} sm={12}>
					<div className={classes.headerContainer}>
						<div className={classes.titleSearch}>
							<span className={classes.globalMainHeadline} style={{margin:0}}>{label}</span>

							{_.isFunction(onChange) &&
							<TextField
								className={classes.searchBox}
								value={value}
								onChange={event => onChange(event)}
								label="Filter"
								fullWidth
							/>
							}
						</div>

						{!!(buttonText) &&
						<div className={classes.buttonAlign}>
							<AddButton
								url={url}
								buttonText={buttonText}
								canMakeUpdates={canMakeUpdates}
							/>
						</div>
						}
					</div>
				</Grid>
			</Grid>
		);
	}
}

FilterHeader.propTypes = {
	label: PropTypes.string.isRequired,
	value : PropTypes.string,
	onChange : PropTypes.func,
	classes: PropTypes.object.isRequired,
	url: PropTypes.string,
	buttonText : PropTypes.string,
	canMakeUpdates : PropTypes.bool,
};

export default _.flow([
	withStyles(styles),
])(FilterHeader);
