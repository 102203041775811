import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';


const styles = () => ({
	addButton : {
		verticalAlign : 'middle',
		textDecoration: 'none',
		marginBottom: 0,
		borderRadius: 100
	},
});

class AddButton extends Component {
	render () {
		const {canMakeUpdates } = this.props;
		return (
			<Fragment>
				{canMakeUpdates && this.renderActive()}
				{!canMakeUpdates && this.renderDisabled()}
			</Fragment>
		);
	}

	renderActive() {
		const { classes, url, buttonText} = this.props;
		return (
			<Link to={url} style={{textDecoration: 'none'}}>
				<Button variant="contained" color="primary" className={classes.addButton}>
					{buttonText}
				</Button>
			</Link>
		);
	}

	renderDisabled() {
		const { classes, buttonText} = this.props;
		return (
			<Tooltip
				title="Write access is disabled"
				enterDelay={300}
			>
				<Button variant="contained" color="primary" className={classes.addButton}>
					{buttonText}
				</Button>
			</Tooltip>
		);
	}
}

AddButton.propTypes = {
	url: PropTypes.string.isRequired,
	buttonText : PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	canMakeUpdates : PropTypes.bool,
};

export default _.flow([
	withStyles(styles),
])(AddButton);
