import _ from 'lodash';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import classNames from 'classnames';

import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import {gridMargin} from "./general";


const styles = (theme) => {
	return {
		root: {
			flexGrow: 1,
			//height: 50,
		},
		input: {
			display: 'flex',
			padding: 0,
			height : 'auto',
		},
		valueContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			flex: 1,
			alignItems: 'center',
			overflow: 'hidden',
		},
		chip: {
			margin: `4px 8px`,
		},
		chipFocused: {
			backgroundColor: emphasize(
				theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
				0.08,
			),
		},
		noOptionsMessage: {
			padding: `2px 4px`,
		},
		singleValue: {
			fontSize: 16,
		},
		placeholder: {
			position: 'absolute',
			left: 2,
			fontSize: 16,
		},
		paper: {
			position: 'absolute',
			zIndex: 1001,
			marginTop: 4,
			left: 0,
			right: 0,
		},
		divider: {
			height: 2,
		},
		fetching : {
			fontStyle : 'italic',
			padding : '10px 0px',
			color : '#777',
		},
	}
};

function NoOptionsMessage(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	/** @namespace props.selectProps.textFieldProps */
	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: props.selectProps.classes.input,
					inputRef: props.innerRef,
					children: props.children,
					...props.innerProps,
				},
			}}
			{...props.selectProps.textFieldProps}
		/>
	);
}

function Option(props) {
	return (
		<MenuItem
			buttonRef={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
			}}
			{...props.innerProps}
		>
			{props.children}
		</MenuItem>
	);
}

function Placeholder(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.placeholder}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function SingleValue(props) {
	return (
		<Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function ValueContainer(props) {
	return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
	return (
		<Chip
			tabIndex={-1}
			label={props.children}
			className={classNames(props.selectProps.classes.chip, {
				[props.selectProps.classes.chipFocused]: props.isFocused,
			})}
			onDelete={props.removeProps.onClick}
			deleteIcon={<CancelIcon {...props.removeProps} />}
		/>
	);
}

function Menu(props) {
	return (
		<Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
			{props.children}
		</Paper>
	);
}

// noinspection JSUnusedGlobalSymbols
const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
};

class FormTypeToSelect extends PureComponent {
	state = {
		value : null,
		isFocus : false,
	};

	//- react-select uses an object. I unwrap that object and just use the value
	//- since that is what I store and comes from redux
	handleChange = (option) => {
		const value = (option) ? option.value : undefined;
		this.props.onChange(value, this.props.path);
	};

	handleFocus = () => {
		this.setState({ isFocus : true });
	};

	handleBlur = () => {
		this.setState({ isFocus : false });
	};

	render () {
		const {classes, options, gridProps} = this.props;

		if (_.isEmpty(options)) {
			return (<div className={classes.fetching}>Loading options...</div>);
		}

		const {
			label,
			error, helpText, disabled,
		} = this.props;

		const { isFocus } = this.state;

		let value = this.props.value;


		if (_.isEmpty(options)) {
			value = null;
		} else {

			//- This could be a group
			if (_.first(options).options) {

				let totalOptions = [];
				options.forEach(optionGroup => {
					totalOptions = totalOptions.concat(optionGroup.options);
				});
				value = _.find(totalOptions, {value});
			} else {
				value = _.find(options, {value});
			}
		}

		//let value, onChange, error;
		const hasError = !!error;

		const content = (
			<FormControl fullWidth={true} error={hasError} disabled={disabled} margin={'dense'}>
				<Select
					classes={classes}
					options={options}
					components={components}
					value={value}
					onChange={this.handleChange}
					placeholder={''}
					isClearable={true}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					textFieldProps={{
						label: label,
						InputLabelProps: {
							shrink: !!(value || isFocus),
						},
					}}
				/>
				{hasError && <FormHelperText>{error}</FormHelperText>}
				{(helpText && !hasError) && <FormHelperText>{helpText}</FormHelperText>}
			</FormControl>
		);

		if (gridProps) {
			_.defaults(gridProps, { xs : 12 });
			return (<Grid item {...gridProps} style={gridMargin}>{content}</Grid>);
		} else {
			return content;
		}
	}
}

FormTypeToSelect.propTypes = {
	label : PropTypes.string,
	value :	PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	path: PropTypes.string.isRequired,
	onChange : PropTypes.func.isRequired,
	error : PropTypes.string,

	classes : PropTypes.object,
	helpText : PropTypes.string,
	placeholder : PropTypes.string,

	//searchUsers: PropTypes.func,
	//users : PropTypes.array,

	options : PropTypes.array,
	gridProps : PropTypes.object,

};

export default _.flow([
	withStyles(styles),
])(FormTypeToSelect);