import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Route,
	Redirect,
} from 'react-router-dom';

class AuthRoute extends Component {
	render () {
		const {
			accessToken,
			component: Component,
			...rest
		} = this.props;

		return (
			<Route {...rest} render={function (props) {
				const {location} = props;
				if (accessToken) {
					return (<Component {...props}/>);
				} else {
					return (
						<Redirect to={{
							pathname: '/login',
							state: {from: location}
						}}/>
					);
				}
			}}/>
		);
	}
}

AuthRoute.propTypes = {
	accessToken : PropTypes.string,
	component : PropTypes.object,
	location : PropTypes.object,
};

function mapStateToProps (state) {
	return { accessToken : state.auth.accessToken };
}

export default connect(mapStateToProps)(AuthRoute);
//export default AuthRoute;
