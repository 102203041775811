import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiSearch} from '../modules/utility';

const apiBase = 'station';
//const item = 'station';
//const idsField = 'stationIds';
//const onSuccessRedirectTo = `/station/list`;

const search = (p = {}) => {
	const {callback = _.noop} = p;
	return dispatch => {
		apiSearch({
			base : apiBase,
			dispatch,
			callback,
		});
	};
};

/*
const get = ({params = {}, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.get`,
			params,
			dispatch,
			callback,
		});
	};
};
*/

/*
const add = ({params, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.add`,
			listNameToUpdate: idsField,
			params,
			dispatch,
			savingMessage: `Adding new ${item}...`,
			savedMessage : `New ${item} was created successfully. 
				Click continue to return to the ${item}s page.`,
			onSuccessRedirectTo,
			delay:350,
			callback,
		});
	};
};

const update = ({params, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.update`,
			params,
			dispatch,
			savingMessage: `Updating ${item}...`,
			savedMessage : `${_.capitalize(item)} updated successfully. 
				Click continue to return to the ${item}s page.`,
			onSuccessRedirectTo,
			delay:350,
			callback,
		});
	};
};
*/
/** @namespace state.lists.stationIds **/
/** @namespace state.maps.station **/

const mapStateToProps = (state) => {
	let stations = [];
	if (state.lists.stationIds) {
		stations = _.map(state.lists.stationIds, id => state.maps.station[id]);
	}

	return {
		stations,
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	//getStation : get,
	searchStations : search,
	//addStation : add,
	//updateStation : update,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
);
