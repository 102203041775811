import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';

// material ui
import { withStyles } from '@material-ui/core/styles';

//- components / form
import FormSubmitButton from '../../components/form/FormSubmitButton';
import FormText from '../../components/form/FormText';
import SectionHeader from "../../components/SectionHeader";
import PermissionPicker from "../../components/advancedForm/PermissionPicker";
import FormContainer from "../../components/FormContainer";

// containers
import AuthUserContainer from '../../containers/AuthUser';
import UserContainer from '../../containers/User';

momentDurationFormatSetup(moment);

const styles = () => {
	return {};
};

class SaveUser extends Component {
	constructor(props) {
		super(props);

		const action = _.get(this.props, 'match.params.action');
		const userId = parseInt(_.get(this.props, 'match.params.userId'), 10);
		const isUpdate = (action === 'update');

		this.state = {
			isUpdate,
			userId,
			formFieldError: {},
			permissionMap : {},
		};
	}

	componentDidMount () {
		const {user, getUser,} = this.props;
		const {userId, isUpdate,} = this.state;

		if (!isUpdate) { return; }

		if (user) {
			this.fillForm({user});
		} else {
			getUser({
				params: {userIds: [userId]}
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapShot) {
		const { user } = this.props;

		if (_.isUndefined(prevProps.user) && !_.isUndefined(user)) {
			this.fillForm({ user });
		}
	}

	fillForm = ({ user }) => {
		if (!user) { return; }

		const newState = {};
		[
			'email',
			'firstName',
			'lastName',
			'title',
			'mobilePhoneNumber',
		].forEach(key => {
			if (_.isNil(user[key])) { return; }
			newState[key] = user[key];
		});

		const { permissions } = user;

		newState.permissionMap = {};
		_.forEach(permissions, id => {
			newState.permissionMap[id] = true;
		});


		this.setState(newState);
	};

	//- Path is always a string
	//- value is never an event (value.target)
	onChange = (value, path) => {
		const arrayPath = _.toPath(path);
		const basePath = _.head(arrayPath);
		let base = this.state[basePath];
		let newState = {};

		if (_.isArray(base) || _.isObject(base)) {
			base = _.cloneDeep(base);
			_.set(base, arrayPath.slice(1), value);
			newState[basePath] = base;
		} else {
			newState[basePath] = value;
		}

		//- Clear any errors on the path
		let formFieldError = Object.assign({}, this.state.formFieldError);
		delete formFieldError[path];
		newState.formFieldError = formFieldError;

		this.setState(newState);
	};



	handleSave = () => {
		const {
			addUser,
			updateUser,
			showErrors,
		} = this.props;

		const {isUpdate, userId} = this.state;

		const formFieldError = {};

		const requiredFields = [
			{path: 'email', label: 'Email'},
			{path: 'firstName', label: 'First name'},
			{path: 'lastName', label: 'Last name'},
		];

		if (!isUpdate) {
			requiredFields.push(
				{path : 'password', label : 'Password'},
				{path : 'retypePassword', label : 'Retype Password'},
			);
		}

		requiredFields.forEach(({path, label})=> {
			if (!this.state[path]) {
				formFieldError[path] = `${label} is required`;
			}
		});

		const {
			email,
			password,
			retypePassword,
			firstName,
			lastName,
			title,
			mobilePhoneNumber,
			permissionMap,
		} = this.state;

		if (!isUpdate && password !== retypePassword) {
			formFieldError.password = `Passwords don't match`;
			formFieldError.retypePassword = `Passwords don't match`;
		}

		const permissions = [];
		_.keys(permissionMap).forEach(key => {
			if (permissionMap[key]) {
				permissions.push(key);
			}
		});

		//- Get all the values and do individual checks
		if (_.keys(formFieldError).length) {
			this.setState({formFieldError});
			showErrors(_.values(formFieldError));
			return;
		}

		const params = {
			email,
			password,
			firstName,
			lastName,
			title,
			mobilePhoneNumber,
			permissions,
		};

		this.setState({
			isSavingOpen: true,
			savingStatus : 'saving'
		});

		if (!isUpdate) {
			addUser({
				params,
			});
		} else {
			params.userId = userId;
			updateUser({
				params,
			});
		}
	};

	getInputProps = (path, defaultValue) => {
		return {
			key : path,
			path,
			value  : this.getValue(path, defaultValue),
			checked  : this.getValue(path, defaultValue),
			error : this.getError(path),
			onChange : this.onChange,
			gridProps : { md : 12 },
		};
	};

	getValue  = (path, defaultValue) => {
		const _d = (_.isUndefined(defaultValue)) ? '' : defaultValue;
		return _.get(this.state, path, _d);
	};

	getError = (path) => {
		return _.get(this.state.formFieldError, path);
	};

	render () {
		const {
			permissions,
		} = this.props;

		const {
			isUpdate,
		} = this.state;

		if (!permissions || !_.includes(permissions,'canAddAndUpdateUsers')) {
			return (<div>You do not have valid permissions to view this page.</div>);
		}

		const action = (isUpdate) ? 'Update' : 'Add';
		return (<FormContainer headline={`${action} User`}>
			<SectionHeader label='Login' gridProps={{md : 12 }} />

			<FormText
				label={'Email'}
				helpText={'Used for login'}
				{...this.getInputProps('email')}
			/>

			<FormText
				label={'Password'}
				inputType={'password'}
				{...this.getInputProps('password')}
			/>

			<FormText
				label={'Retype password'}
				inputType={'password'}
				{...this.getInputProps('retypePassword')}
			/>

			<SectionHeader label='Info' gridProps={{md : 12 }} />

			<FormText
				{...this.getInputProps('firstName')}
				label={'First Name'}
				gridProps={{ sm : 6 }}
			/>

			<FormText
				{...this.getInputProps('lastName')}
				label={'Last Name'}
				gridProps={{ sm : 6 }}
			/>

			<FormText
				label={'Title'}
				{...this.getInputProps('title')}
				gridProps={{ sm : 6 }}
			/>

			<FormText
				{...this.getInputProps('mobilePhoneNumber')}
				label={'Mobile Phone #'}
				gridProps={{ sm : 6 }}
			/>

			<PermissionPicker
				{...this.getInputProps('permissionMap')}
			/>

			<FormSubmitButton
				onClick={this.handleSave}
				buttonText={`${action} User`}
			/>

		</FormContainer>);
	}
}

SaveUser.propTypes = {
	addUser : PropTypes.func.isRequired,
	updateUser : PropTypes.func.isRequired,
	user : PropTypes.object,

	showErrors : PropTypes.func.isRequired,
	authUser : PropTypes.object,
	classes: PropTypes.object.isRequired,
};

export default _.flow([
	withStyles(styles),
	AuthUserContainer,
	UserContainer,
])(SaveUser);
