import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { theme } from './themes';
import PropTypes from 'prop-types';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import store, { history } from './store';

import Layout from './pages/Layout';
import Login from './pages/Login';

import Startup from './Startup';
import AuthRoute from './containers/AuthRoute';

import {
	BrowserRouter,
	Route,
	Switch,
} from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const NoMatch = ({ location }) => (
	<div>
		<h3>No match for <code>{location.pathname}</code></h3>
	</div>
);

NoMatch.propTypes = {
	location : PropTypes.object,
};

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Startup>
					<ConnectedRouter history={history}>
						<BrowserRouter>
							<MuiThemeProvider theme={theme}>
								<MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
									<div className="App">
										<Switch>
											<Route path="/login" component={Login} />
											<AuthRoute path="/" component={Layout} />
										</Switch>
									</div>
								</MuiPickersUtilsProvider>
							</MuiThemeProvider>
						</BrowserRouter>
					</ConnectedRouter>
				</Startup>
			</Provider>
		);
	}
}
export default App;
