import _ from 'lodash';
import moment from 'moment';
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// Components
import FilterHeader from "../../components/table/FilterHeader";
import EnhancedTableHead from '../../components/table/EnhancedTableHead';

// Helpers
import {isFiltered, sortTable} from '../../libs/tableUtilities';
import { getGlobalClasses } from '../../themes';

// Containers
import AuthUserContainer from '../../containers/AuthUser';
import UserContainer from '../../containers/User';


const styles = (theme) => {
	return {
		...getGlobalClasses(theme),
		table : {
			width: '100%',
			tableLayout: 'auto',
		},
	};
};

const userColumns = [
	{ id: 'firstName', label: 'First name' },
	{ id: 'lastName',  label: 'Last name' },
	{ id: 'email', label: 'Email'  },
	{ id: 'title', label: 'title' },
	{ id: 'mobilePhoneNumber', label: 'Mobile #'},
	{ id: 'dateCreated',  label: 'Date Created',},
	{ id: 'edit', label : 'Edit', isEdit : true }
];

class UserList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			order: 'asc',
			orderBy: 'firstName',
			filter: '',
		};
	}

	componentDidMount () {
		if (!this.props.users.length) {
			this.props.searchUsers();
		}
	}

	handleSort = (orderBy) => {
		let order = 'desc';
		if (this.state.orderBy === orderBy && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleEdit = (userId) => {
		this.props.history.push(`/user/update/${userId}`);
	};

	handleFilter = (event) => {
		this.setState({ filter : event.target.value });
	};

	render() {
		const {
			classes,
			users,
			permissions,
		} = this.props;

		const {
			order,
			orderBy,
			filter,
		} = this.state;

		if (!permissions || !_.includes(permissions,'canAddAndUpdateUsers')) {
			return (<div>You do not have valid permissions to view this page.</div>);
		}
		if (!users) {
			return (<div>Fetching users...</div>);
		}

		let userRows = users.reduce((result, user) => {
			const {
				userId, firstName, lastName, email, dateCreated,
				title, mobilePhoneNumber,
			} = user;

			const dateCreatedFormatted = moment(dateCreated).format('MMM Do, YYYY');

			const row = {
				userId, firstName, lastName,
				email, title, dateCreated,
				mobilePhoneNumber, dateCreatedFormatted,
			};

			if (isFiltered({filter, row, fields : ['firstName', 'lastName', 'email']})) {
				return result;
			}

			result.push(row);
			return result;
		}, []);

		userRows = sortTable({ rows : userRows, order, orderBy});

		return (<Fragment>
				<FilterHeader
					value={this.state.filter}
					onChange={this.handleFilter}
					label={'Users'}
					buttonText="Add User"
					url="/user/add"
					canMakeUpdates={true}
				/>
				<Paper className={classes.globalMainPaper}>
					<Table size='small' className={classes.table}>
						<EnhancedTableHead
							columns={userColumns}
							order={order}
							orderBy={orderBy}
							onRequestSort={this.handleSort}
						/>
						<TableBody>
							{userRows.map(row => {
								return (
									<TableRow
										hover
										className={classes.row}
										key={row.userId}
									>
										<TableCell>{row.firstName}</TableCell>
										<TableCell>{row.lastName}</TableCell>
										<TableCell>{row.email}</TableCell>
										<TableCell>{row.title}</TableCell>
										<TableCell>{row.mobilePhoneNumber}</TableCell>
										<TableCell>{row.dateCreatedFormatted}</TableCell>
										<TableCell align={'center'} style={{whiteSpace:'nowrap'}}>
											<Button
												className={classes.button}
												onClick={() => this.handleEdit(row.userId)}
											>
												Edit
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Paper>
			</Fragment>
		);
	}
}

UserList.propTypes = {
	classes: PropTypes.object.isRequired,

	searchUsers: PropTypes.func.isRequired,
	users : PropTypes.array,

	permissions : PropTypes.array,
	order: PropTypes.string,
	orderBy: PropTypes.string,
	history : PropTypes.object,
	theme: PropTypes.object.isRequired,
};

export default _.flow([
	withStyles(styles, { withTheme: true }),
	UserContainer,
	AuthUserContainer,
])(UserList);