import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';

const styles = () => ({
	bannerContainer : {
		width: 400,
		height : 100,
		border: '1px solid #0094E0',
		boxShadow : '0 3px 8px 0 rgba(0,0,0,0.20)',
		borderRadius : 8,
		position : 'relative',
		overflow : 'hidden',
		display : 'flex',
		alignItems : 'center',
	},
	taglineContainer : {
		width : 34,
		position : 'absolute',
		top : 0,
		right : 0,
		height : '100%',
		backgroundColor : '#0094E0',
		writingMode: 'vertical-rl',
		textOrientation: 'mixed',
		color : 'white',
		display : 'flex',
		alignItems : 'center',
		justifyContent : 'center',
		textTransform : 'uppercase',
		fontWeight : 'bold',
		fontSize : 17,
		whiteSpace : 'nowrap',

	},

	graphic : {
		width : 70,
		height : 70,
		borderRadius : 1000,
		overflow : 'hidden',
		position : 'relative',
		marginLeft : 10,
		marginRight : 10,
		border : '1px solid #D8D8D8',
	},

	innerGraphicOverlay : {
		opacity : .2,
		backgroundColor : '#ddd',
		position : 'absolute',
		height : '100%',
		width : '100%',
		top : 0,
		left : 0,
	},
	innerGraphic : {
		width : '100%',
		height : '100%',
		backgroundSize : 'cover',
	},
	messagingContainer : {

	},
	title : {
		fontSize : 20,
		fontWeight : 800,
		color : '#333',
		letterSpacing : '1px',
		lineHeight : 1.4
	},
	message : {
		fontSize : 16,
		fontWeight : 700,
		color : '#444',
		lineHeight : 1.5,
		letterSpacing : '.5px',
	},
	dateTimeLine : {
		fontSize : 14,
		color : '#777',
	}
});

class EventBanner extends Component {
	render () {
		const {
			title, message, dateTimeLine,
			bannerColor, bannerTagline, resourceUrl,
			classes
		} = this.props;

		let taglineSize = 18;
		if (bannerTagline) {
			if (bannerTagline.length > 6 && bannerTagline.length < 10) {
				taglineSize = 15;
			} else if (bannerTagline.length > 10) {
				taglineSize = 12;
			}
		}

		return (
			<div className={classes.bannerContainer} style={{ borderColor : bannerColor}}>
				<div className={classes.graphic}>
					<div style={{backgroundImage : `url(${resourceUrl})` }} className={classes.innerGraphic}/>
					<div className={classes.innerGraphicOverlay} />
				</div>
				<div className={classes.messagingContainer}>
					<div className={classes.title}>{title}</div>
					<div className={classes.message}>{message}</div>
					<div className={classes.dateTimeLine}>{dateTimeLine}</div>
				</div>
				<div className={classes.taglineContainer} style={{backgroundColor : bannerColor}}>
					<div style={{fontSize : taglineSize }}>{bannerTagline}</div>
				</div>
			</div>
		);
	}
}

EventBanner.propTypes = {
	title : PropTypes.string,
	message : PropTypes.string,
	dateTimeLine : PropTypes.string,
	bannerColor : PropTypes.string,
	bannerTagline : PropTypes.string,
	resourceId : PropTypes.number,
	resourceUrl : PropTypes.string,
	classes: PropTypes.object.isRequired,
};

export default _.flow([
	withStyles(styles),
])(EventBanner);
