import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {getGlobalClasses} from "../themes";

const styles = (theme) => {
	return {
		...getGlobalClasses(theme),
	};
};

class FormContainer extends Component {
	render () {
		const {headline, classes, children} = this.props;

		return (
			<Paper className={classes.globalMainPaper}>
				<form>
					<div className={classes.globalMainHeadline}>{headline}</div>
					<Grid container>
						<Grid item xs={12} sm={10} md={10} lg={8}>
							<Grid spacing={2} container>
								{children}
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Paper>
		);
	}
}

FormContainer.propTypes = {
	headline: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	children : PropTypes.node.isRequired,
};

export default _.flow([
	withStyles(styles),
])(FormContainer);
