import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// material ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// containers
import AuthUserContainer from '../../containers/AuthUser';
import Paper from "@material-ui/core/Paper";
import {getGlobalClasses} from "../../themes";

//- icons
import StationContainer from "../../containers/Station";
import StationMap from "./StationMap";

const styles = (theme) => {
	return {
		...getGlobalClasses(theme),
	};
};

class StationMapDisplay extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	componentDidMount () {
		const {searchStations, stations} = this.props;
		if (_.isEmpty(stations)) {
			searchStations({});
		}
	}

	onDragStart = () => {
		this.setState({ customers : [] });
	};
	onDragEnd = (circleCenter) => {
		this.setState({ circleCenter });
	};
	onMapDragEnd = (mapCenter) => {
		this.setState({mapCenter});
	};

	render () {
		const {classes, stations} = this.props;

		if (_.isEmpty(stations)) {
			return (<div>Fetching stations...</div>)
		}

		const mapCenter = {
			lat: 32.72480751180992,
			lng: -117.22946187220765,
		};
		const circleCenter = {
			lat: 32.72480751180992,
			lng: -117.22946187220765,
		};

		return (<Paper className={classes.globalMainPaper}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<StationMap
						mapCenter={mapCenter}
						circleCenter={circleCenter}
						zoom={10}
						stations={stations}
						googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBjbDdlqMJZU7VmhRCNf-Wg9D8v985I8Dc"
						loadingElement={<div style={{ height: `700px` }} />}
						containerElement={<div style={{ height: `1000px`, width : '100%' }} />}
						mapElement={<div style={{ height: `100%` }} />}
						onDragStart={this.onDragStart}
						onDragEnd={this.onDragEnd}
						onMapDragEnd={this.onMapDragEnd}
					/>
				</Grid>
			</Grid>
		</Paper>);
	}
}

StationMapDisplay.propTypes = {
	searchStations : PropTypes.func.isRequired,
	stations : PropTypes.array,
	showErrors : PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default _.flow([
	withStyles(styles),
	AuthUserContainer,
	StationContainer,
])(StationMapDisplay);

