import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {getGlobalClasses} from "../themes";
import { post } from '../modules/utility';

import totalUsers from '../images/dashboardIcons/icons8-account.svg';
import totalUsersWithEmail from '../images/dashboardIcons/icons8-contact-details.svg';
import totalUsersWithPush from '../images/dashboardIcons/icons8-push-notifications.svg';
import totalUsersWithLocation from '../images/dashboardIcons/icons8-user-location.svg';


import  { AreaChart,
	Area,
	Legend,
	ResponsiveContainer,
	XAxis, YAxis, CartesianGrid, Tooltip,
	//-pie
	LineChart, Line,
} from 'recharts';

const styles = (theme) => {
	return {
		...getGlobalClasses(theme),
		title: {
			fontSize: 50,
			fontFamily: "'Roboto Condensed', sans-serif",
		},
		subTitle: {
			fontSize: 30,
			marginBottom: 30,
			color: '#555',
			borderBottom: '1px solid #eee',
			paddingBottom: 10,
			marginTop: 60,
		},
		contentTitle: {
			fontSize: 24,
		},
		content: {
			fontSize: 18,
		},
		whatsNewItemContainer: {
			display: 'flex',
			alignItems: 'row',
			padding: '20px 30px',
		},
		iconSection: {
			paddingRight: 20,
			textAlign: 'center',
		},
		description: {
			fontSize: 20,
		},
		chartTitle: {
			fontSize: 18,
			textTransform: 'uppercase',
			letterSpacing: 1,
			color: '#555555',
			marginTop: 30,
		},
		bigNumber: {
			fontSize: 40,
			fontWeight: 800,
		},
		statLabel: {
			fontSize: 16,
			color: '#666',
			textTransform: 'uppercase',
			letterSpacing: 1,
			marginBottom : 10,
		},
		cardLayout: {
			padding: '15px 20px',
		},
		statIcon: {
			display: 'inline-block',
			width: 55,
			height: 55,
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			verticalAlign: 'middle',
			marginRight : 15,
		},
		totalUsers: {
			backgroundImage: `url('${totalUsers}')`,
		},
		totalUsersWithEmail : {
			backgroundImage: `url('${totalUsersWithEmail}')`,
		},
		totalUsersWithLocation: {
			backgroundImage: `url('${totalUsersWithLocation}')`,
		},
		totalUsersWithPush: {
			backgroundImage: `url('${totalUsersWithPush}')`,
		}
	};
};

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		this.getStats();
	}

	getStats = () => {
		this.setState({ searching: true });

		post({
			method: 'admin.customer.getStats',
			params: {
				//afterDate : moment().subtract(30, 'days').format('YYYY-MM-DD'),
			},
			callback: (err, r) => {
				if (err) {
					return this.setState({
						searching: false,
						searchErrors: err,
					});
				}

				this.formatStats({ result : r});
			}
		});
	};

	formatStats = ({ result }) => {

		const thirtyDaysBack  = moment().subtract(30, 'days');
		const total = {};
		const chartRows = [];
		_.keys(result).forEach(key => {
			const dateMoment = moment(key, 'YYYY-MM-DD');
			const name = dateMoment.format('M/D');

			const newRow = { name };
			['ios', 'android'].forEach(platform => {
				[
					'totalUsers', 'totalUsersWithLocation',
					'totalUsersWithPush', 'totalUsersWithEmail'
				].forEach(s => {
					const val = _.get(result, [key, platform, s], 0);
					newRow[`${platform}_${s}`] = val;

					if (!newRow[`total_${s}`]) {
						newRow[`total_${s}`] = 0;
					}
					newRow[`total_${s}`] += val;

					if (!total[s]) {
						total[s] = 0;
					}
					total[s] += val;
				});
			});

			['ios', 'android'].forEach(platform => {
				[
					'totalUsers', 'totalUsersWithLocation',
					'totalUsersWithPush', 'totalUsersWithEmail'
				].forEach(s => {
					const platformTotal = newRow[`${platform}_${s}`];
					const total = newRow[`${platform}_totalUsers`];
					newRow[`${platform}_${s}_percentage`] = platformTotal / total;
				});
			});

			if (dateMoment.isBefore(thirtyDaysBack)) {
				return;
			}
			chartRows.push(newRow);
		});

		this.setState({
			chartRows,
			total,
			searching: false,
		});

	};

	render () {
		const {
			classes,
		} = this.props;
		const {
			chartRows,
		} = this.state;

		if (_.isEmpty(chartRows)) {
			return (<div>Fetching...</div>);
		}

		return (
			<Paper className={classes.globalMainPaper}>
				<div className={classes.title}>Welcome to Pump!</div>

				<div className={classes.subTitle}>The Big Picture</div>
				<Grid container spacing={2}>
					{this.renderStat({
						title : 'Total Users',
						key : 'totalUsers'
					})}

					{this.renderStat({
						title : 'Valid User Locations',
						key : 'totalUsersWithLocation'
					})}


					{this.renderStat({
						title : 'Users with Push Enabled',
						key : 'totalUsersWithPush'
					})}

					{this.renderStat({
						title : 'Total Completed Sign Ups',
						key : 'totalUsersWithEmail'
					})}
				</Grid>

				<div className={classes.subTitle}>30 Day Sliding Stat Window</div>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						{this.renderChart({
							title : 'New users by platform for last 30 days',
							dataSuffix : 'totalUsers'
						})}
					</Grid>

					<Grid item xs={6}>
						{this.renderChart({
							title : 'New users with location',
							dataSuffix : 'totalUsersWithLocation'
						})}
					</Grid>
					<Grid item xs={6}>
						{this.renderLine({
							title : 'Percentage of Location Conversions',
							dataSuffix : 'totalUsersWithLocation_percentage'
						})}
					</Grid>

					<Grid item xs={6}>
						{this.renderChart({
							title : 'New users with notifications',
							dataSuffix : 'totalUsersWithPush'
						})}
					</Grid>
					<Grid item xs={6}>
						{this.renderLine({
							title : 'Percentage of Notification Conversions',
							dataSuffix : 'totalUsersWithPush_percentage'
						})}
					</Grid>


					<Grid item xs={6}>
						{this.renderChart({
							title : 'New users that signed up ',
							dataSuffix : 'totalUsersWithEmail'
						})}
					</Grid>
					<Grid item xs={6}>
						{this.renderLine({
							title : 'Percentage of Signup Conversions',
							dataSuffix : 'totalUsersWithEmail_percentage'
						})}
					</Grid>
				</Grid>
			</Paper>
		);
	}

	renderStat = ({ title, key }) => {
		const { classes } = this.props;
		const { total } = this.state;

		console.log("TOTAL", total);

		return (
			<Grid item xs={3}>
				<Card className={classes.cardLayout}>
					<div className={classes.statLabel}>{title}</div>
					<div style={{display : 'flex', alignItems : 'center'}}>
						<div className={clsx(classes.statIcon, classes[key])} />
						<div className={classes.bigNumber}>{total[key]}</div>
					</div>
				</Card>
			</Grid>
		);
	};

	renderChart = ({ title, dataSuffix }) => {
		const { classes } = this.props;
		const { chartRows} = this.state;

		const color1 = '#00BCD4';
		const color2 = '#8BC34A';

		return (<Fragment>
				<div className={classes.chartTitle}>
					{title}
				</div>
				<ResponsiveContainer width="100%" height={300}>
					<AreaChart data={chartRows}
					           margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
						<defs>
							<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor={color1} stopOpacity={0.8}/>
								<stop offset="95%" stopColor={color1} stopOpacity={0}/>
							</linearGradient>
							<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor={color2} stopOpacity={0.8}/>
								<stop offset="95%" stopColor={color2} stopOpacity={0}/>
							</linearGradient>
						</defs>
						<XAxis dataKey="name" />
						<YAxis domain={[5000, 'auto']} />
						<CartesianGrid strokeDasharray="3 3" />
						<Tooltip />
						<Legend verticalAlign="top" height={36}/>
						<Area type="monotone"
						      name={'iOS'}
						      dataKey={`ios_${dataSuffix}`}
						      stroke={color1} fillOpacity={1}
						      fill="url(#colorPv)"
						      stackId={1}
						/>
						<Area type="monotone"
						      name={'Android'}
						      dataKey={`android_${dataSuffix}`}
						      stroke={color2} fillOpacity={1}
						      fill="url(#colorUv)"
						      stackId={1}
						/>
					</AreaChart>
				</ResponsiveContainer>
			</Fragment>
		);
	};

	renderLine = ({ title, dataSuffix }) => {
		const { classes } = this.props;
		const { chartRows} = this.state;

		const color1 = '#00BCD4';
		const color2 = '#8BC34A';

		return (<Fragment>
				<div className={classes.chartTitle}>
					{title}
				</div>
				<ResponsiveContainer width="100%" height={300}>
					<LineChart data={chartRows}
					           margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
						<defs>
							<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor={color1} stopOpacity={0.8}/>
								<stop offset="95%" stopColor={color1} stopOpacity={0}/>
							</linearGradient>
							<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor={color2} stopOpacity={0.8}/>
								<stop offset="95%" stopColor={color2} stopOpacity={0}/>
							</linearGradient>
						</defs>
						<XAxis dataKey="name" />
						<YAxis domain={['auto', 'auto']} tickFormatter={(value) => { return _.round(value * 100, 1) + '%'; }} />
						<CartesianGrid strokeDasharray="3 3" />
						<Tooltip formatter={(value) => { return _.round(value * 100, 1) + '%'; }}/>
						<Legend verticalAlign="top" height={36}/>
						<Line type="monotone"
						      name={'iOS'}
						      dataKey={`ios_${dataSuffix}`}
						      stroke={color1} fillOpacity={1}
						      fill="url(#colorPv)"
						/>
						<Line type="monotone"
						      name={'Android'}
						      dataKey={`android_${dataSuffix}`}
						      stroke={color2} fillOpacity={1}
						      fill="url(#colorUv)"
						/>
					</LineChart>
				</ResponsiveContainer>
			</Fragment>
		);
	}




}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	authUser : PropTypes.object,
};

export default _.flow([
	withStyles(styles),
])(Dashboard);
