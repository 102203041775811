import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from "@material-ui/core/styles";
import {gridMargin} from "./general";

const styles = () => {
	return {
		spacing : {
			paddingTop : 2,
			height : 30,
		},
	};
};

class FormCheckbox extends PureComponent {
	render() {
		const {label, checked, path,
			gridProps, onChange, classes} = this.props;

		const _onChange = event => {
			onChange(event.target.checked, path);
		};

		const content = (
			<FormControlLabel label={label} classes={{ root : classes.spacing }} control={
				<Checkbox
					color="primary"
					checked={checked}
					onChange={_onChange}
				/>
			}
			/>
		);

		if (gridProps) {
			_.defaults(gridProps, { xs : 12 });
			return (<Grid item {...gridProps} style={gridMargin}>{content}</Grid>);
		} else {
			return content;
		}
	}
}

FormCheckbox.propTypes = {
	label : PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	checked : PropTypes.bool.isRequired,
	onChange : PropTypes.func.isRequired,
	classes : PropTypes.object,
	error : PropTypes.string,
	disabled : PropTypes.bool,
	gridProps : PropTypes.object,
};

export default _.flow([
	withStyles(styles),
])(FormCheckbox);