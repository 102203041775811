import _ from 'lodash';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import StationContainer from '../../containers/Station';
import FormTypeToSelect from '../form/FormTypeToSelect';

const styles = () => {
	return {};
};

class StationPicker extends PureComponent {
	state = {
		value : '',
	};

	componentDidMount () {
		if (_.isEmpty(this.props.stations)) {
			this.props.searchStations();
		} else {
			this.formatTheStations();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {stations} = this.props;

		if (!_.isEmpty(stations) && prevProps.stations !== stations) {
			this.formatTheStations();
		}
	}

	formatTheStations = () => {
		const { stations } = this.props;

		//	const formattedStations = _.sortBy(this.props.stations, 'lastName').map(station => {
		let formattedStations = [];

		stations.forEach(station => {
			const { stationId, stationName, number } = station;
			console.log(station);

			let label  = `${number} - ${stationName}`;
			if (!number) {
				label  = `${stationName}`;
			}

			formattedStations.push({
				value: stationId,
				label,
			});
		});
		formattedStations = _.sortBy(formattedStations, 'label');

		this.setState({ formattedStations });
	};

	render () {
		const {classes} = this.props;
		const {formattedStations} = this.state;

		if (_.isEmpty(formattedStations)) {
			return (<div className={classes.fetching} style={{height : 68}}>Fetching stations...</div>);
		}

		return (
			<FormTypeToSelect
				{...this.props}
				options={formattedStations}
			/>
		);
	}
}

StationPicker.propTypes = {
	label : PropTypes.string,
	value :	PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	path: PropTypes.string.isRequired,
	onChange : PropTypes.func.isRequired,
	error : PropTypes.string,

	classes : PropTypes.object,
	helpText : PropTypes.string,
	placeholder : PropTypes.string,

	searchStations: PropTypes.func,
	stations : PropTypes.array,
};

StationPicker.defaultProps =  {
	label : 'Employee',
	placeholder : 'Search for employee',
};

export default _.flow([
	withStyles(styles),
	StationContainer,
])(StationPicker);