import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import momentDurationFormatSetup from "moment-duration-format";

// material ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//- components / form
import FormSubmitButton from "../../components/form/FormSubmitButton";
import FormText from "../../components/form/FormText";
import SectionHeader from "../../components/SectionHeader";
import EventBanner from "../../components/EventBanner";
import StationPicker from "../../components/advancedForm/StationPicker";
import FormContainer from "../../components/FormContainer";

// containers
import AuthUserContainer from "../../containers/AuthUser";
import NewsItemContainer from "../../containers/NewsItem";
import FormSelect from "../../components/form/FormSelect";
import FormDatePicker from "../../components/form/FormDatePicker";

momentDurationFormatSetup(moment);

const typeToBannerInfo = {
  /*
  flashSale: {
    color: "#F44335",
    tagline: "Sale",
    resourceId: 2,
    resourceUrl:
      "https://s3-us-west-2.amazonaws.com/pump-production-resources/2019729-21125-ejsYO.jpeg",
  },
  comingSoon: {
    color: "#89D235",
    tagline: "Coming Soon",
    resourceId: 3,
    resourceUrl:
      "https://s3-us-west-2.amazonaws.com/pump-production-resources/2019729-5672-q7vs1.jpeg",
  },
  grandOpening: {
    color: "#0094E0",
    tagline: "Open Now",
    resourceId: 4,
    resourceUrl:
      "https://s3-us-west-2.amazonaws.com/pump-production-resources/2019729-21125-fwLIZ.jpeg",
  },
  */

  flashSale: {
    color: "#F44335",
    tagline: "Sale",
    resourceId: 5,
    resourceUrl:
      "https://s3-us-west-2.amazonaws.com/pump-production-resources/2021316-3819-KeM6R.jpeg",
  },
  comingSoon: {
    color: "#89D235",
    tagline: "Coming Soon",
    resourceId: 6,
    resourceUrl:
      "https://s3-us-west-2.amazonaws.com/pump-production-resources/2021316-3819-yKv1X.jpeg",
  },
  grandOpening: {
    color: "#0094E0",
    tagline: "Open Now",
    resourceId: 7,
    resourceUrl:
      "https://s3-us-west-2.amazonaws.com/pump-production-resources/2021316-2725-2fuWq.jpeg",
  },
};

const styles = () => {
  return {};
};

class SaveNewsItem extends Component {
  constructor(props) {
    super(props);

    const action = _.get(this.props, "match.params.action");
    const newsItemId = parseInt(
      _.get(this.props, "match.params.newsItemId"),
      10
    );
    const isUpdate = action === "update";

    this.state = {
      isUpdate,
      newsItemId,
      formFieldError: {},
      permissionMap: {},
      //- TODO commen tout
      type: "flashSale",
      title: "E85 Flash Sale ⚡️",
      message: "Sacramento - Mobile Station",
      dateTimeLine: "May 14th 9am - Closing",
    };
  }

  componentDidMount() {
    const { newsItem, getNewsItem } = this.props;
    const { newsItemId, isUpdate } = this.state;

    if (!isUpdate) {
      return;
    }

    if (newsItem) {
      this.fillForm({ newsItem });
    } else {
      getNewsItem({
        params: { newsItemIds: [newsItemId] },
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    const { newsItem } = this.props;

    if (_.isUndefined(prevProps.newsItem) && !_.isUndefined(newsItem)) {
      this.fillForm({ newsItem });
    }
  }

  fillForm = ({ newsItem }) => {
    if (!newsItem) {
      return;
    }

    const newState = {};
    [
      "type",
      "title",
      "message",
      "dateTimeLine",
      `startDate`,
      "endDate",
      "destinationStationId",
    ].forEach((key) => {
      if (_.isNil(newsItem[key])) {
        return;
      }
      newState[key] = newsItem[key];
    });

    this.setState(newState);
  };

  //- Path is always a string
  //- value is never an event (value.target)
  onChange = (value, path) => {
    const arrayPath = _.toPath(path);
    const basePath = _.head(arrayPath);
    let base = this.state[basePath];
    let newState = {};

    if (_.isArray(base) || _.isObject(base)) {
      base = _.cloneDeep(base);
      _.set(base, arrayPath.slice(1), value);
      newState[basePath] = base;
    } else {
      newState[basePath] = value;
    }

    //- Clear any errors on the path
    let formFieldError = Object.assign({}, this.state.formFieldError);
    delete formFieldError[path];
    newState.formFieldError = formFieldError;

    this.setState(newState);
  };

  handleSave = () => {
    const { addNewsItem, updateNewsItem, showErrors } = this.props;

    const { isUpdate, newsItemId } = this.state;

    const formFieldError = {};

    const requiredFields = [
      { path: "type", label: "Type" },
      { path: "title", label: "Title" },
      { path: "message", label: "Message" },
      { path: "dateTimeLine", label: "Date / time line" },
      { path: "startDate", label: "Date / time line" },
      { path: "endDate", label: "Date / time line" },
      { path: "destinationStationId", label: "Station" },
    ];

    requiredFields.forEach(({ path, label }) => {
      if (!this.state[path]) {
        formFieldError[path] = `${label} is required`;
      }
    });

    //- Get all the values and do individual checks
    if (_.keys(formFieldError).length) {
      this.setState({ formFieldError });
      showErrors(_.values(formFieldError));
      return;
    }

    const {
      type,
      title,
      message,
      dateTimeLine,
      startDate,
      endDate,
      destinationStationId,
    } = this.state;

    const params = {
      type,
      title,
      message,
      dateTimeLine,
      startDate,
      endDate,
      destinationStationId,
    };

    const { color, tagline, resourceId } = typeToBannerInfo[type] || {};

    params.bannerColor = color;
    params.bannerTagline = tagline;
    params.graphicResourceId = resourceId;

    this.setState({
      isSavingOpen: true,
      savingStatus: "saving",
    });

    if (!isUpdate) {
      addNewsItem({
        params,
      });
    } else {
      params.newsItemId = newsItemId;
      updateNewsItem({
        params,
      });
    }
  };

  getInputProps = (path, defaultValue) => {
    return {
      key: path,
      path,
      value: this.getValue(path, defaultValue),
      checked: this.getValue(path, defaultValue),
      error: this.getError(path),
      onChange: this.onChange,
      gridProps: { md: 12 },
    };
  };

  getValue = (path, defaultValue) => {
    const _d = _.isUndefined(defaultValue) ? "" : defaultValue;
    return _.get(this.state, path, _d);
  };

  getError = (path) => {
    return _.get(this.state.formFieldError, path);
  };

  render() {
    //const {} = this.props;

    const { isUpdate, type, title, message, dateTimeLine } = this.state;

    let bannerColor, bannerTagline, bannerResourceId, bannerResourceUrl;

    if (type) {
      const { color, tagline, resourceId, resourceUrl } =
        typeToBannerInfo[type] || {};
      bannerColor = color;
      bannerTagline = tagline;
      bannerResourceId = resourceId;
      bannerResourceUrl = resourceUrl;
    }

    const action = isUpdate ? "Update" : "Add";
    return (
      <FormContainer headline={`${action} Event`}>
        <SectionHeader label="Messaging" gridProps={{ md: 12 }} />

        <FormSelect
          {...this.getInputProps("type")}
          label={"Type of event"}
          options={[
            { display: "Sale", value: "flashSale" },
            { display: "Now Open", value: "grandOpening" },
            { display: "Coming Soon", value: "comingSoon" },
          ]}
        />

        <FormText {...this.getInputProps("title")} label={"Title"} />

        <FormText {...this.getInputProps("message")} label={"Message"} />

        <FormText
          {...this.getInputProps("dateTimeLine")}
          label={"Date / Time line"}
        />

        <SectionHeader label="Info" gridProps={{ md: 12 }} />

        <FormDatePicker
          {...this.getInputProps("startDate")}
          label={"Start sate"}
          helpText={"Used to sort the event in the feed"}
        />

        <FormDatePicker
          {...this.getInputProps("endDate")}
          label={"End date"}
          helpText={"Will not display event after this date"}
        />

        <StationPicker
          {...this.getInputProps("destinationStationId")}
          label={"Station (destination when event is clicked)"}
        />

        <SectionHeader label="Sample Preview" gridProps={{ md: 12 }} />

        <Grid item md={12}>
          <div style={{ paddingBottom: 20, fontWeight: 300, color: "#333" }}>
            (note : sizes and fonts vary based on mobile device)
          </div>

          <EventBanner
            title={title}
            message={message}
            dateTimeLine={dateTimeLine}
            bannerColor={bannerColor}
            bannerTagline={bannerTagline}
            resourceId={bannerResourceId}
            resourceUrl={bannerResourceUrl}
          />
        </Grid>

        <FormSubmitButton
          onClick={this.handleSave}
          buttonText={`${action} Event`}
        />
      </FormContainer>
    );
  }
}

SaveNewsItem.propTypes = {
  addNewsItem: PropTypes.func.isRequired,
  updateNewsItem: PropTypes.func.isRequired,
  newsItem: PropTypes.object,

  showErrors: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default _.flow([
  withStyles(styles),
  AuthUserContainer,
  NewsItemContainer,
])(SaveNewsItem);
