import _ from 'lodash';
import moment from 'moment';
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// Components
import FilterHeader from "../../components/table/FilterHeader";
import EnhancedTableHead from '../../components/table/EnhancedTableHead';

// Helpers
import {isFiltered, sortTable} from '../../libs/tableUtilities';
import { getGlobalClasses } from '../../themes';

// Containers
import AuthUserContainer from '../../containers/AuthUser';
import NewsItemContainer from '../../containers/NewsItem';


const styles = (theme) => {
	return {
		...getGlobalClasses(theme),
		table : {
			width: '100%',
			tableLayout: 'auto',
		},
		current : {
			fontWeight : 800,
			color : '#388E3C',
		},
		expired : {
			fontWeight : 400,
			color : '#9E9E9E',
		}
	};
};

const newsItemColumns = [
	{ id: 'type', label: 'Type' },
	{ id: 'station', label: 'Station' },
	{ id: 'title',  label: 'title' },
	{ id: 'messaging', label: 'Messaging'  },
	{ id: 'startAndEndDates', label: 'Start / End Dates' },
	{ id: 'status',  label: 'Status'},
	{ id: 'createdSort',  label: 'Created By',},
	{ id: 'edit', label : 'Edit', isEdit : true }
];

class NewItemList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			order: 'asc',
			orderBy: 'firstName',
			filter: '',
		};
	}

	componentDidMount () {
		if (!this.props.newsItems.length) {
			this.props.searchNewsItems();
		}
	}

	handleSort = (orderBy) => {
		let order = 'desc';
		if (this.state.orderBy === orderBy && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleEdit = (newsItemId) => {
		this.props.history.push(`/newsItem/update/${newsItemId}`);
	};

	handleFilter = (event) => {
		this.setState({ filter : event.target.value });
	};

	render() {
		const {
			classes,
			newsItems,
			userMap,
			stationMap,
		} = this.props;

		const {
			order,
			orderBy,
			filter,
		} = this.state;

		if (!newsItems) {
			return (<div>Fetching events...</div>);
		}

		let newsItemRows = newsItems.reduce((result, newsItem) => {

			const {
				newsItemId,
				type, title, message, dateTimeLine,
				creatorUserId,
				startDate, endDate, dateCreated,
				destinationStationId,
				status,
			} = newsItem;

			const dateCreatedFormatted = moment(dateCreated).format('MMM Do, YYYY');

			const createdByUser = userMap[creatorUserId] || { firstName : 'Missing'};
			const station = stationMap[destinationStationId] || { stationName : 'Missing'};

			const messaging = (<Fragment>
				<div>{message}</div>
				<div>{dateTimeLine}</div>
				</Fragment>
			);

			const typeMap = {
				flashSale : 'Sale',
				comingSoon : 'Coming Soon',
				grandOpening : 'Now Open',
			};
			const statusMap = {
				current : 'Current',
				expired : 'Expired',
			};

			const startAndEndDates = (<Fragment>
				<div>{moment(startDate).format('MMM Do, YYYY')}</div>
				<div>{moment(endDate).format('MMM Do, YYYY')}</div>
			</Fragment>);

			const created = (<Fragment>
				<div>{createdByUser.firstName} {createdByUser.lastName}</div>
				<div>{dateCreatedFormatted}</div>
			</Fragment>);

			const { stationName, number} = station;
			let label  = `${number} - ${stationName}`;
			if (!number) { label  = `${stationName}`; }

			const row = {
				newsItemId,
				type,
				typeFormatted : typeMap[type] || 'missing',
				title,
				messaging,
				startAndEndDates,
				station : label,
				created,
				createdSort : dateCreated,
				status,
				statusDisplay : statusMap[status],
				dateCreatedFormatted,
			};

			if (isFiltered({filter, row, fields : ['type', 'message', 'title']})) {
				return result;
			}

			result.push(row);
			return result;
		}, []);

		newsItemRows = sortTable({ rows : newsItemRows, order, orderBy});

		return (<Fragment>
				<FilterHeader
					value={this.state.filter}
					onChange={this.handleFilter}
					label={'Event Feed'}
					buttonText="Add New Event"
					url="/newsItem/add"
					canMakeUpdates={true}
				/>
				<Paper className={classes.globalMainPaper}>
					<Table size='small' className={classes.table}>
						<EnhancedTableHead
							columns={newsItemColumns}
							order={order}
							orderBy={orderBy}
							onRequestSort={this.handleSort}
						/>
						<TableBody>
							{newsItemRows.map(row => {
								return (
									<TableRow
										hover
										className={classes.row}
										key={row.newsItemId}
									>
										<TableCell>{row.typeFormatted}</TableCell>
										<TableCell>{row.station}</TableCell>
										<TableCell>{row.title}</TableCell>
										<TableCell>{row.messaging}</TableCell>
										<TableCell>{row.startAndEndDates}</TableCell>
										<TableCell className={classes[row.status]}>{row.statusDisplay}</TableCell>
										<TableCell>{row.created}</TableCell>
										<TableCell align={'center'} style={{whiteSpace:'nowrap'}}>
											<Button
												className={classes.button}
												onClick={() => this.handleEdit(row.newsItemId)}
											>
												Edit
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Paper>
			</Fragment>
		);
	}
}

NewItemList.propTypes = {
	classes: PropTypes.object.isRequired,

	searchNewsItems: PropTypes.func.isRequired,
	newsItems : PropTypes.array,
	userMap : PropTypes.object,
	stationMap : PropTypes.object,

	order: PropTypes.string,
	orderBy: PropTypes.string,
	history : PropTypes.object,
	theme: PropTypes.object.isRequired,
};

export default _.flow([
	withStyles(styles, { withTheme: true }),
	NewsItemContainer,
	AuthUserContainer,
])(NewItemList);