import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Grid from "@material-ui/core/Grid";

const styles = () => ({
	sectionHeader : {
		margin : '40px 0px 5px 0px',
		textAlign : 'left',
		textTransform : 'uppercase',
		fontSize : 14,
		color : '#666',
		fontWeight : 900,
		letterSpacing : 2,
	},
});

class SectionHeader extends Component {
	render () {
		const {label, classes, gridProps} = this.props;

		const content = (
			<div className={classes.sectionHeader}>{label}</div>
		);

		if (gridProps) {
			_.defaults(gridProps, { xs : 12 });
			return (<Grid item {...gridProps}>{content}</Grid>);
		} else {
			return content;
		}

	}
}

SectionHeader.propTypes = {
	label: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	gridProps : PropTypes.object,
};

export default _.flow([
	withStyles(styles),
])(SectionHeader);
