import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiSearch, apiFetch } from '../modules/utility';

const apiBase = 'user';
const item = 'user';
const idsField = 'userIds';
const onSuccessRedirectTo = `/user/list`;

const search = (p = {}) => {
	const {callback = _.noop} = p;
	return dispatch => {
		apiSearch({
			base : apiBase,
			dispatch,
			callback,
		});
	};
};


const get = ({params = {}, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.get`,
			params,
			dispatch,
			callback,
		});
	};
};

const add = ({params, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.add`,
			listNameToUpdate: idsField,
			params,
			dispatch,
			savingMessage: `Adding new ${item}...`,
			savedMessage : `New ${item} was created successfully. 
				Click continue to return to the ${item}s page.`,
			onSuccessRedirectTo,
			delay:350,
			callback,
		});
	};
};

const update = ({params, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.update`,
			params,
			dispatch,
			savingMessage: `Updating ${item}...`,
			savedMessage : `${_.capitalize(item)} updated successfully. 
				Click continue to return to the ${item}s page.`,
			onSuccessRedirectTo,
			delay:350,
			callback,
		});
	};
};

const mapStateToProps = (state, ownProps) => {

	//- Get a single user and the list of users
	const userId = (ownProps.userId)
		? ownProps.userId
		: _.get(ownProps, ['match', 'params', 'userId']);
	const user = _.get(state.maps, ['user', userId]);

	let users = [];
	if (state.lists.userIds) {
		users = _.map(state.lists.userIds, id => state.maps.user[id]);
	}

	return {
		user,
		users,
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	getUser : get,
	addUser : add,
	updateUser : update,
	searchUsers : search,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
);
