import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authenticate } from './modules/auth';

class Startup extends Component {
	componentDidMount() {
		const accessToken = this.props.accessToken;

		if (accessToken) {
			this.props.authenticate({accessToken});
		}
	}

	render() {
		return this.props.children;
	}
}

Startup.propTypes = {
	accessToken : PropTypes.string,
	authenticate : PropTypes.func,
	children : PropTypes.node,
};

const mapStateToProps = state => {
	let accessToken = 2;

	if (state.auth) {
		accessToken = state.auth.accessToken;
	}
	return {accessToken};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	authenticate,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Startup);