import moment from 'moment';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import { gridMargin } from './general';
import _ from "lodash";

export default class FormDatePicker extends PureComponent {
	render () {

		const {
			label, value, path, onChange, required,
			error, helpText, disabled, gridProps, isYearPicker
		} = this.props;

		const _onChange = date => {
			onChange(moment(date).format('YYYY-MM-DD'), path);
		};

		const hasError = !!error;
		const helperText = (hasError)
			? error
			: (helpText) ? helpText : null;

		let content;
		if (isYearPicker) {
			content = (
				<DatePicker
					required={required}
					label={label}
					value={value || null}
					onChange={_onChange}
					animateYearScrolling
					format={'M/D/YYYY'}
					disabled={disabled}
					error={hasError}
					fullWidth={true}
					margin={'dense'}
					helperText={helperText}
					disableFuture
					openTo="year"
					views={["year", "month", "day"]}
				/>
			);
		} else {

			content = (
				<DatePicker
					required={required}
					label={label}
					value={value || null}
					showTodayButton
					onChange={_onChange}
					animateYearScrolling
					format={'dddd, MMMM Do'}

					disabled={disabled}
					error={hasError}
					fullWidth={true}
					margin={'dense'}
					helperText={helperText}
				/>
			);
		}

		if (gridProps) {
			_.defaults(gridProps, { xs : 12 });
			return (<Grid item {...gridProps} style={gridMargin}>{content}</Grid>);
		} else {
			return content;
		}
	}
}

FormDatePicker.propTypes = {
	label : PropTypes.string.isRequired,
	value : PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	onChange : PropTypes.func.isRequired,
	error : PropTypes.string,

	helpText : PropTypes.string,
	disabled : PropTypes.bool,
	isYearPicker : PropTypes.bool,
	required : PropTypes.bool,
	gridProps : PropTypes.object,
};

FormDatePicker.defaultProps = {
	isYearPicker : false,
	required : false,
};
