import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiSearch, apiFetch } from '../modules/utility';

const apiBase = 'newsItem';
const item = 'newsItem';
const idField = 'newsItemId';
const idsField = 'newsItemIds';
const onSuccessRedirectTo = `/newsItem/list`;

const search = (p = {}) => {
	const {callback = _.noop} = p;
	return dispatch => {
		apiSearch({
			base : apiBase,
			params : {
				orderByStartDateDesc : true,
			},
			dispatch,
			callback,
		});
	};
};

const get = ({params = {}, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.get`,
			params,
			dispatch,
			callback,
		});
	};
};

const add = ({params, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.add`,
			listNameToUpdate: idsField,
			params,
			dispatch,
			savingMessage: `Adding new ${item}...`,
			savedMessage : `New ${item} was created successfully. 
				Click continue to return to the ${item}s page.`,
			onSuccessRedirectTo,
			delay:350,
			callback,
		});
	};
};

const update = ({params, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.update`,
			params,
			dispatch,
			savingMessage: `Updating ${item}...`,
			savedMessage : `${_.capitalize(item)} updated successfully. 
				Click continue to return to the ${item}s page.`,
			onSuccessRedirectTo,
			delay:350,
			callback,
		});
	};
};

const mapStateToProps = (state, ownProps) => {

	//- Get a single item and the list of items
	const id = (ownProps[idField])
		? ownProps[idField]
		: _.get(ownProps, ['match', 'params', idField]);

	const newsItem = _.get(state.maps, ['newsItem', id]);

	let newsItems = [];
	if (state.lists[idsField]) {
		newsItems = _.map(state.lists[idsField], id => state.maps.newsItem[id]);
	}

	return {
		newsItem,
		newsItems,
		userMap : state.maps.user,
		stationMap : state.maps.station,
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	getNewsItem : get,
	addNewsItem : add,
	updateNewsItem : update,
	searchNewsItems : search,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
);
