import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Route, Switch }  from 'react-router-dom';
import LeftDrawer from '../components/LeftDrawer';
import LayoutContainer from '../containers/Layout';
import SavingDialog from '../components/dialogs/SavingDailog';
import ErrorDialog from '../components/dialogs/ErrorDialog';

//- Pages / Route
import Dashboard from './Dashboard';
import Administration from "./Administration";
import UserList from './User/List';
import UserSave from './User/Save';

import NewsItemList from './NewsItem/List';
import NewsItemSave from './NewsItem/Save';

import NotificationList from './Notification/List';
import NotificationSave from './Notification/Save';

import CustomerEmails from './CustomerEmails';

import StationMapDisplay from "./Notification/StationMapDisplay";

const drawerWidth = 240;
const styles = theme => {
	return {
		root: {
			width: '100%',
			height: 'auto',
			zIndex: 1,
			overflow: 'hidden',
			backgroundColor : '#e5e5e6'
		},
		appFrame: {
			//position: 'relative',
			zIndex : 10,
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		},
		drawerFrame: {
			zIndex : 1,
			display : 'flex',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		},
		appBar: {
			width: '100%',
			position : "relative",
		},
		drawerPaper: {
			//position: 'relative',
			//height: 'auto',
			//width: drawerWidth,
			width: drawerWidth,
			backgroundColor : '#2d2f31',
		},
		content: {
			display : 'flex',
			flexDirection : 'column',
			width: '100%',
			height: 'calc(100% - 56px)',
			overflowX: 'scroll',
			padding : 24,
			[theme.breakpoints.up('md')]: {
				height: 'calc(100% - 64px)',
			},
		},
		hide: {
			display: 'none',
		},
		header : {
			fontWeight : 400,
			fontSize : 20,
			textTransform : 'uppercase'
		}
	};
};


const NoMatch = () => (
	<div>
		<h2>Invalid URL. Please check your URL or contact support</h2>
	</div>
);

class Layout extends Component {

	constructor(props){
		super(props);
		this.state = {
			drawerOpen : false,
			isSuccessDialogOpen : false,
			isErrorDialogOpen : false,
		};
	}

	componentDidMount() {
		const {width} = this.props;
		this.setState({drawerOpen: isWidthUp('md', width)});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { width } = this.props;

		if (this.props.width !== prevProps.width) {
			this.setState({drawerOpen: isWidthUp('md', width)});
		}
	}

	handleDrawerToggle = () => {
		this.setState({ drawerOpen : !this.state.drawerOpen });
	};

	handleSavingClosed = () => {
//		this.props.clearSaving();
//		this.props.history.push(this.props.onSuccessRedirectTo);
	};

	continueSavingDialog = () => {
		this.props.clearSaving();
		this.props.history.push(this.props.onSuccessRedirectTo);
	};


	render() {
		// noinspection JSMismatchedCollectionQueryUpdate
		const {
			width,
			classes,
			savingStatus,
			savingMessage,
			savedMessage,
			errorMessages = [],
		} = this.props;
		const { drawerOpen } = this.state;

		const styles = {
			header: {
				paddingLeft: drawerOpen ? drawerWidth : 0
			},
			container: {
				//margin: '80px 20px 20px 15px',
				paddingLeft: drawerOpen && isWidthUp('md', width) ? drawerWidth : 0
			}
		};

		const isSavingOpen = !!(savingStatus);
		const isErrorDialogOpen = (errorMessages.length !== 0);

		return (
			<div className={classes.root}>
				<div className={classes.appFrame}>
					<AppBar className={classes.appBar} style={styles.header}>
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={this.handleDrawerToggle}
							>
								<MenuIcon />
							</IconButton>

							<Typography variant="h6" color="inherit" noWrap className={classes.header}>
								Pearson User Management System
							</Typography>
						</Toolbar>
					</AppBar>
				</div>
				<div className={classes.drawerFrame} style={styles.container}>
					<LeftDrawer
						drawerOpen={drawerOpen}
						drawerPaper={classes.drawerPaper}
						logout={this.props.logout}
						authUser={this.props.authUser}
					/>
					<div className={classes.content}>
						<Switch>
							<Route exact path="/" component={Dashboard} />
							<Route exact path="/administration" component={Administration} />
							<Route exact path="/dashboard" component={Dashboard} />

							<Route exact path="/user/list" component={UserList} />
							<Route exact path="/user/:action(add|update)/:userId?" component={UserSave} />


							<Route exact path="/newsItem/list"
							       component={NewsItemList} />
							<Route exact path="/newsItem/:action(add|update)/:newsItemId?"
							       component={NewsItemSave} />

							<Route exact path="/notification/list"
							       component={NotificationList} />
							<Route exact path="/notification/:action(add|update)/:notificationId?"
							       component={NotificationSave} />

							<Route exact path="/customerEmails" component={CustomerEmails} />
							<Route exact path="/stationMapDisplay" component={StationMapDisplay} />

							<Route component={NoMatch}/>
						</Switch>
					</div>
				</div>

				<ErrorDialog
					onClose={this.props.clearError}
					errorMessages={errorMessages}
					open={isErrorDialogOpen}
				/>

				{/*
				<SavingDialog
					onClose={this.handleSuccessClose}
					successMessages={successMessages}
					open={isSuccessDialogOpen}
				/>
				*/}

				<SavingDialog
					open={isSavingOpen}
					onClose={this.handleSavingClosed}
					onContinue={this.continueSavingDialog}
					processingText={savingMessage}
					successText={savedMessage}
					status={savingStatus}
				/>

			</div>
		);
	}
}

Layout.propTypes = {
	classes: PropTypes.object.isRequired,
	logout : PropTypes.func.isRequired,
	width : PropTypes.string,

	errorMessages : PropTypes.array,
	clearError : PropTypes.func,

	//- props for generic saving
	onSuccessRedirectTo : PropTypes.string,
	savingStatus : PropTypes.string,
	savingMessage : PropTypes.string,
	savedMessage : PropTypes.string,
	clearSaving : PropTypes.func,

	history : PropTypes.object,
	authUser : PropTypes.object,
};

export default _.flow([
	withStyles(styles),
	withWidth(),
	LayoutContainer,
])(Layout);
