import _ from 'lodash';
import { apiFetch } from './utility';
export const LOGIN_REQUESTED = 'auth/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const AUTH_SUCCESS = 'auth/AUTH_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const LOGOUT = 'auth/LOGOUT';

const initialState = {
	accessToken : undefined,
	isAuthenticating : false,
	authSuccess : false,
	authFailure : false,
	authErrors : [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_REQUESTED:
			return {
				...state,
				isAuthenticating: true,
				authErrors : [],
				authFailure : false,
				authSuccess : false,
			};

		case AUTH_SUCCESS:
			return {
				...state,
				authUser : action.authUser,
				isAuthenticating: false,
				authSuccess : true,
				authFailure : false,
			};

		case LOGIN_SUCCESS:
			return {
				...state,
				accessToken : action.accessToken,
				authUser : action.authUser,
				isAuthenticating: false,
				authSuccess : true,
				authFailure : false,
			};

		case LOGIN_FAILURE:
			return {
				...state,
				accessToken : undefined,
				authErrors : action.errors,
				isAuthenticating : false,
				authSuccess : false,
				authFailure : true,
			};

		case LOGOUT:
			return {
				...state,
				accessToken : undefined,
			};

		default:
			return state;
	}
};

export const authenticate = ({ accessToken }) => {
	return dispatch => {
		apiFetch({
			method: 'user.authenticate',
			skipErrors : true,
			params: { accessToken },
			dispatch,
			callback: function (errors, r) {

				if (errors && errors.length) {
					dispatch({
						type: LOGIN_FAILURE,
						errors
					});
					return;
				}

				const authUser = _.get(r, ['collections','userCollection',0]);
				if (!authUser) {
					dispatch({
						type: LOGIN_FAILURE,
						errors : ['Server did not return authUser']
					});
					return;
				}

				//- its all good
				dispatch({
					type: LOGIN_SUCCESS,
					accessToken,
					authUser,
				});
			}
		});
	};
};

export const login = ({ email, password }) => {
	return dispatch => {
		dispatch({
			type: LOGIN_REQUESTED
		});

		const params = {
			password, email
		};

		setTimeout(function () {
			apiFetch({
				method: 'user.login',
				skipErrors : true,
				params,
				dispatch,
				callback: function (errors, r) {

					if (errors && errors.length) {
						dispatch({
							type: LOGIN_FAILURE,
							errors
						});
						return;
					}

					const accessToken = r.accessToken;
					const authUser = _.get(r, ['collections','userCollection',0]);

					if (!accessToken) {
						dispatch({
							type: LOGIN_FAILURE,
							errors : ['Server did not return accessToken']
						});
						return;
					}

					if (!authUser) {
						dispatch({
							type: LOGIN_FAILURE,
							errors : ['Server did not return authUser']
						});
						return;
					}

					//- its all good
					dispatch({
						type: LOGIN_SUCCESS,
						accessToken,
						authUser,
					});
				}
			});
		},500);
	};
};

export const logout = () => {
	return dispatch => {
		dispatch({
			type: LOGOUT
		});
	};
};
