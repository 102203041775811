/* global fetch */
import _ from 'lodash';
import { MAP_COLLECTIONS } from './maps';
import { SET_LIST, UPDATE_LIST } from './lists';
import { SAVING_EVENT, SAVED_EVENT, CLEAR_SAVING, ERROR_EVENT } from './general';

let accessToken;
export const updateAccessToken = (state) => {
	if (!state.auth) { return; }
	if (state.auth.accessToken === accessToken) { return; }
	accessToken = state.auth.accessToken;
};

export const post = ({ method, params, callback }) => {
	fetch('/api/', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			method,
			params: _.merge({ accessToken: accessToken}, params),
		})
	})
		.then(r => r.json())
		.then(r => {
			if (r.result) {
				const result = r.result;

				/** @namespace result.paramErrors **/
				if (result.paramErrors) {
					throw(result.paramErrors);
				}

				return callback(null, result);
			} else if (r.error) {
				throw(r.error);
			} else {
				throw(new Error("Unknown error return from API"));
			}
		})
		.catch(err => {
			const errors = _makeErrors(err);
			return callback(errors);
		});
};

export const apiSearch = ({base, method, dispatch, keyBy, params, listName, idName, callback}) => {
	const apiMethod = (method) ? method : `${base}.search`;
	const apiParams = _.merge({ accessToken: accessToken}, params);
	const setListName = (listName) ? listName : `${base}Ids`;
	const setIdName = (idName) ? idName : `${base}Ids`;

	fetch('/api/', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			method: apiMethod,
			params: apiParams,
		})
	})
		.then(r => {
			return r.json();
		})
		.then(r => {
			if (r.result) {
				const result = r.result;

				/** @namespace result.paramErrors **/
				if (result.paramErrors) {
					throw(result.paramErrors);
				}

				dispatch({
					type: MAP_COLLECTIONS,
					collections: _.get(result, ['collections'], [])
				});

				dispatch({
					type: SET_LIST,
					listName: setListName,
					keyBy,
					ids: _.get(result, [setIdName], [])
				});

				return callback(null, result);

			} else if (r.error) {
				throw(r.error);
			} else {
				throw(new Error("Unknown error return from API"));
			}
		})
		.catch(err => {
			const errors = _makeErrors(err);
			dispatch({
				type: ERROR_EVENT,
				errorMessages: errors,
			});
			return callback(errors);
		});
};

export const apiFetch = (p) => {
	const {
		method, dispatch, params, files, listNameToUpdate,
		savingMessage, savedMessage, onSuccessRedirectTo,
		skipErrors = false,
		delay = 0,
		callback,
	} = p;

	if (savingMessage) {
		dispatch({
			type: SAVING_EVENT,
			savingMessage,
		});
	}

	const apiParams = _.merge({}, params, {accessToken : accessToken});
	if (params && params.accessToken) {
		apiParams.accessToken = params.accessToken;
	}

	let url = '/api';
	const jsonRequest = JSON.stringify({
		method: method,
		params : apiParams,
	});

	let body;
	const headers = {
		'Accept': 'application/json',
	};

	if (files && files.length) {
		const data = new FormData();
		files.forEach(file => {
			data.append(file.name, file.data);
		});
		data.append('request', jsonRequest);
		body = data;
		//- Content-Type should be multipart filled in by the browser
	} else {
		headers['Content-Type'] = 'application/json';
		body = jsonRequest;
	}

	_.delay(() => {

		fetch(url, {
			method: 'POST',
			headers,
			body
		})
			.then(r => {
				return r.json();
			})
			.then(r => {

				if (r.result) {
					const result = r.result;

					/** @namespace result.paramErrors **/
					if (result.paramErrors) {
						throw(result.paramErrors);
					}

					dispatch({
						type: MAP_COLLECTIONS,
						collections: _.get(result, ['collections'], [])
					});

					if (listNameToUpdate) {
						dispatch({
							type: UPDATE_LIST,
							listName: listNameToUpdate,
							ids: result[listNameToUpdate],
						});
					}

					if (savedMessage) {
						dispatch({
							type: SAVED_EVENT,
							savedMessage,
							onSuccessRedirectTo: onSuccessRedirectTo,
						});
					}

					return callback(null, result);

				} else if (r.error) {
					throw(r.error);
				} else {
					throw(new Error("Unknown error return from API"));
				}
			})
			.catch(err => {
				const errors = _makeErrors(err);

				console.log("CAUGHT HE ERORR");

				dispatch({
					type: CLEAR_SAVING,
				});

				//- TODO need a better way todo this. Login is skipping
				if (!skipErrors) {
					dispatch({
						type: ERROR_EVENT,
						errorMessages: errors,
					});
				}
				return callback(errors);
			});
	}, delay);
};

/*
export const apiRemove = ({base, dispatch, id, callback}) => {
	const method = `${base}.remove`;
	//const apiParams = _.merge({ accessToken: accessToken}, params);

	const apiParams = {
		accessToken,
		[`${base}Id`] : id,
	};

	fetch('/api/', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			method: method,
			params: apiParams,
		})
	})
		.then(r => {
			return r.json();
		})
		.then(r => {
			if (r.result) {
				const result = r.result;

				if (result.paramErrors) {
					throw(result.paramErrors);
				}

				dispatch({
					type: REMOVE_FROM_LIST,
					listName: `${base}Ids`,
					id: id
				});

				return callback(null, result);

			} else if (r.error) {
				throw(r.error);
			} else {
				throw(new Error("Unknown error return from API"));
			}
		})
		.catch(err => {
			const errors = _makeErrors(err);
			dispatch({
				type: ERROR_EVENT,
				errorMessages: errors,
			});
			return callback(errors);
		});
};
*/

function _makeErrors(err){
	let errors;

	if (_.isArray(err)) {
		if (err[0].message) { errors = _.map(err, 'message'); }
		else { errors = err; }
	} else if (_.isObject(err) && err.message) {
		errors = [err.message];
	} else {
		errors = [err];
	}
	console.error('Errors from API', errors);
	return errors;
}