import _ from 'lodash';
import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import FormCheckbox from "../form/FormCheckbox";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import SectionHeader from "../SectionHeader";


const styles = () => {
	return {};
};

class PermissionPicker extends PureComponent {
	render () {
		const {
			label, value, path, onChange, disabled,
			error, helpText,
		} = this.props;


		const permissions = [{
			id: 'canAddAndUpdateUsers',
			display : 'Can add and update users'
		}];

		const hasError = !!error;
		const options = _.sortBy(permissions, 'display').map(permission => {
			const { id, display } = permission;
			return { id, display };
		});

		return (<Fragment>
			<SectionHeader label={label} gridProps={{ md : 12 }}/>
			<FormControl fullWidth={true} error={hasError} disabled={disabled} margin={'dense'}>
				<Grid container spacing={2}>
					{options.map(({id, display}) => {
						const childPath = `${path}.${id}`;
						return (
							<Grid item xs={12} sm={6} key={childPath}>
								<FormCheckbox
									path={childPath}
									label={display}
									value={id}
									checked={!!value[id]}
									onChange={onChange}
								/>
							</Grid>
						);
					})}
				</Grid>
				{hasError && <FormHelperText>{error}</FormHelperText>}
				{(helpText && !hasError) && <FormHelperText>{helpText}</FormHelperText>}
			</FormControl>
		</Fragment>);
	}
}

PermissionPicker.propTypes = {
	label : PropTypes.string,
	value :	PropTypes.object.isRequired,
	path: PropTypes.string.isRequired,
	onChange : PropTypes.func.isRequired,
	error : PropTypes.string,

	classes : PropTypes.object,
	md : PropTypes.number,
	helpText : PropTypes.string,
};

PermissionPicker.defaultProps =  {
	md : 12,
	label : 'Permissions',
};

export default _.flow([
	withStyles(styles),
])(PermissionPicker);