import _ from 'lodash';
//import { createReducer } from './utility';

export const SET_LIST = 'lists/SET_LIST';
export const UPDATE_LIST = 'lists/UPDATE_LIST';
export const CLEAR_LIST = 'lists/CLEAR_LIST';
export const REMOVE_FROM_LIST = 'lists/REMOVE_FROM_LIST';

const initialState = {};

//- Reducers
export default (state = initialState, action) => {
	switch (action.type) {
		case SET_LIST:
			return setList(state, action);
		case UPDATE_LIST:
			return updateList(state, action);
		case CLEAR_LIST:
			return clearList(state, action);
		case REMOVE_FROM_LIST:
			return removeFromList(state, action);
		default: return state;
	}
};

function setList(state, action) {
	const {ids, listName, keyBy} = action;
	if (!ids.length) { return state; }

	if (keyBy) {
		return _.merge({}, state, { [listName] : { [keyBy] : ids }});
	} else {
		return _.merge({}, state, { [listName] : ids });
	}
}

function customizer2(objValue, srcValue) {
	if (_.isArray(objValue)) {
		return srcValue;
	}
}

//- TODO better way todo this maybe?
function clearList(state, action) {
	const {listName} = action;
	//return _.merge({}, state, { [listName] : [] });
	return _.mergeWith({}, state, { [listName] : [] }, customizer2);
}

function customizer(objValue, srcValue) {
	if (_.isArray(objValue)) {
		return objValue.concat(srcValue);
	}
}

function updateList(state, action) {
	const {ids, listName} = action;
	//- Leave the list empty if it doesn't exist
	if (_.isUndefined(state[listName])) {
		return state;
	}

	if (!ids.length) { return state; }
	return _.mergeWith({}, state, { [listName] : ids }, customizer);
}

function removeFromList(state, action) {
	const {id, listName} = action;

	//- Leave the list empty if it doesn't exist
	if (_.isUndefined(state[listName])) {
		return state;
	}

	if (!id) { return state; }

	const filteredIds = _.filter(state[listName], listId => { return listId !== id; });
	return _.mergeWith({}, state, { [listName] : filteredIds }, customizer2);
}
