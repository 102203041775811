import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {gridMargin} from "./general";

export default class FormSelect extends PureComponent {
	render () {
		const {
			label, value, path, onChange,
			error, options, helpText, disabled, gridProps
		} = this.props;

		const _onChange = event => {
			onChange(event.target.value, path);
		};

		//let value, onChange, error;
		const hasError = !!error;
		const content = (
			<FormControl fullWidth={true} error={hasError} disabled={disabled}>
				<InputLabel htmlFor={path}>{label}</InputLabel>
				<Select
					value={value}
					onChange={_onChange}
					input={<Input name={path} id={path}/>}
				>
					{options.map(option => {
						return (<MenuItem key={option.value} value={option.value}>
							{option.display}
						</MenuItem>);
					})}
				</Select>
				{hasError && <FormHelperText>{error}</FormHelperText>}
				{(helpText && !hasError) && <FormHelperText>{helpText}</FormHelperText>}
			</FormControl>
		);

		if (gridProps) {
			_.defaults(gridProps, { xs : 12 });
			return (<Grid item {...gridProps} style={gridMargin}>{content}</Grid>);
		} else {
			return content;
		}
	}
}

FormSelect.propTypes = {
	label : PropTypes.string.isRequired,
	value :	PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	path: PropTypes.string.isRequired,
	onChange : PropTypes.func.isRequired,
	error : PropTypes.string,

	options : PropTypes.array.isRequired,

	helpText : PropTypes.string,
	disabled : PropTypes.bool,

	gridProps : PropTypes.object,
};

