let currentAccessToken;

export const loadLocalStorage = () => {
	try {
		const sessionSavedState = localStorage.getItem('sessionSavedState');
		if (sessionSavedState === null) {
			return undefined;
		}
		const parsed =  JSON.parse(sessionSavedState);

		//- Old way we used to store auth
		if (parsed.user && parsed.user.accessToken) {
			parsed.auth = parsed.user;
			delete parsed.user;
		}

		console.info('Local storage', parsed);
		return parsed;

	} catch (err) {
		console.warn("Problems loading sessionSavedState");
		return undefined;
	}
};

export const saveLocalStorage = (state) => {
	if (!state.auth) { return; }
	if (state.auth.accessToken === currentAccessToken) { return; }
	currentAccessToken = state.auth.accessToken;

	console.info('Saving to localStorage', state.auth);

	try {
		const sessionSavedState = JSON.stringify({
			auth : { accessToken : state.auth.accessToken }
		});
		localStorage.setItem('sessionSavedState', sessionSavedState);
	} catch (err) {
		console.warn('Failed to save sessionSavedState');
	}
};
