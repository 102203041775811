import { connect } from 'react-redux';
import {login} from "../modules/auth";
import { bindActionCreators } from 'redux';

const mapStateToProps = state => {
	return {
		accessToken: state.auth.accessToken,
		isAuthenticating: state.auth.isAuthenticating,
		authErrors : state.auth.authErrors,
		authSuccess : state.auth.authSuccess,
		authFailure : state.auth.authFailure
	};
};
const mapDispatchToProps = dispatch => bindActionCreators({
	login,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
);
