import 'react-app-polyfill/ie11';
import 'core-js/fn/array/includes';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'string-polyfills';

/*
//- polyfill for ReactJs
function isWhiteSpace(char) {
	return char === " " || char === "\n";
}
if (!String.prototype.trimLeft) {
	String.prototype.trimLeft = function() {
		let i = 0;
		while (i < this.length && isWhiteSpace(this[i])) { i++; }
		return this.substring(i);
	}
}


if (!String.prototype.trimRight) {
	String.prototype.trimRight = function() {
		let i = this.length - 1;
		while (i >= 0 && isWhiteSpace(this[i])) { i--; }
		return this.substring(0, i + 1);
	}
}
*/

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
