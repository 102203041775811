import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {gridMargin} from "./general";

export default class FormText extends PureComponent {
	render () {
		const {
			label, value, onChange, path, inputType,
			error, gridProps, helpText, disabled, required,
		} = this.props;

		const hasError = !!error;
		const _onChange = event => onChange(event.target.value, path);

		const helperText = (hasError)
			? error
			: (helpText) ? helpText : null;

		const content = (
			<TextField
				autoComplete={path}
				disabled={disabled}
				error={hasError}
				fullWidth={true}
				helperText={helperText}
				label={label}
				onChange={_onChange}
				required={required}
				type={inputType}
				value={value}
			/>
		);

		if (gridProps) {
			_.defaults(gridProps, { xs : 12 });
			return (<Grid item {...gridProps} style={gridMargin}>{content}</Grid>);
		} else {
			return content;
		}
	}
}

FormText.propTypes = {
	label : PropTypes.string.isRequired,
	value :	PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	path: PropTypes.string.isRequired,
	onChange : PropTypes.func.isRequired,

	error : PropTypes.string,
	helpText : PropTypes.string,
	disabled : PropTypes.bool,

	inputType : PropTypes.string,
	gridProps : PropTypes.object,
};

FormText.defaultProps = {
	inputType : 'text',
	required : false,
};