import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';

class EnhancedTableHead extends Component {
	createSortHandler(event, property) {
		this.props.onRequestSort(event, property);
	}

	render() {
		const {
			columns,
			order,
			orderBy,
		} = this.props;

		return (
			<TableHead>
				<TableRow>
					{columns.map(column => {
						const { isEdit } = column;
						const isNotSortable = isEdit;

						const style = _.merge({
							zIndex : 1,
							position: 'sticky',
							top : 0,
							backgroundColor : '#fff',
						}, column.style);

						if (isEdit) {
							style.textAlign = 'center';
						}

						return (
							<TableCell
								key={column.id}
								align={(column.numeric) ? 'right' : 'left'}
								style={style}
							>
								{isNotSortable &&
									<span style={{whiteSpace:'nowrap'}}>
										{column.label}
									</span>
								}

								{!isNotSortable &&
								<Tooltip
									title="Sort"
									placement={column.numeric ? 'bottom-end' : 'bottom-start'}
									enterDelay={300}
								>
									<TableSortLabel
										active={orderBy === column.id}
										direction={order}
										onClick={() => { this.createSortHandler(column.id); }}
									>
										<span style={{whiteSpace:'nowrap'}}>
											{column.label}
										</span>
									</TableSortLabel>
								</Tooltip>
								}

							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	columns : PropTypes.array.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;