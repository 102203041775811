import _ from 'lodash';
//import { createReducer } from './utility';

export const MAP_COLLECTIONS = 'maps/MAP_COLLECTIONS';

const initialState = {};

//export default createReducer(initialState, {
//	MAP_COLLECTIONS : mapCollections,
//});

//- Reducers
export default (state = initialState, action) => {
	switch (action.type) {
		case MAP_COLLECTIONS:
			return mapCollections(state, action);
		default: return state;
	}
};

function mapCollections(state, action) {
	const collections = { action };
	if (!_.keys(collections).length) { return state; }

	const newState = _.assign({}, state);
	_.forEach(action.collections, (collection, collectionName) => {
		const stateKey = collectionName.replace(/Collection$/, '');
		const collectionId = `${stateKey}Id`;
		if (!newState[stateKey]) { newState[stateKey] = {}; }
		_.assign(newState[stateKey], _.keyBy(collection, collectionId));
	});
	return newState;
}
