//import _ from 'lodash';
import { connect } from 'react-redux';
import { clearError, clearSaving } from '../modules/general';
import { logout } from '../modules/auth';
import { bindActionCreators } from 'redux';

// XXX memoize
const mapStateToProps = state => {

	return {
		authUser : state.auth.authUser,
		errorMessages : state.general.errorMessages,
		savingMessage : state.general.savingMessage,
		savedMessage : state.general.savedMessage,
		onSuccessRedirectTo : state.general.onSuccessRedirectTo,
		savingStatus : state.general.savingStatus,
	};
};
const mapDispatchToProps = dispatch => bindActionCreators({
	logout,
	clearError,
	clearSaving,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
);
