import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
// import createRootReducer from './index';

import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

//import rootReducer from './modules';
import createRootReducer from './modules';

import { loadLocalStorage, saveLocalStorage } from './localStorage';
import { updateAccessToken } from './modules/utility';

//export const history = createHistory();
export const history = createBrowserHistory();

const initialState = loadLocalStorage();

const enhancers = [];
const middleware = [
	thunk,
	routerMiddleware(history)
];

// noinspection JSUnresolvedVariable
if (process.env.NODE_ENV === 'development') {
	//const devToolsExtension = window.devToolsExtension;
	/** @namespace window.__REDUX_DEVTOOLS_EXTENSION__ */
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}
}

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers
);

const store = createStore(
	//rootReducer,
	createRootReducer(history),
	initialState,
	composedEnhancers
);

store.subscribe(() => {
	saveLocalStorage(store.getState());
	updateAccessToken(store.getState());
});

export default store;