import _ from 'lodash';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import {gridMargin} from "./general";

const styles = () => ({
	submitButton : {
		marginTop : 30,
		fontSize : 18,
		borderRadius : '100px',
		padding : '20px 40px',
		textTransform: 'uppercase',
		whiteSpace : 'nowrap'
	},
});

class FormSubmitButton extends PureComponent {
	render () {
		const {isDisabled } = this.props;
		return (
			<Grid item xs={12} style={gridMargin}>
				{!isDisabled && this.renderActive()}
				{isDisabled && this.renderDisabled()}
			</Grid>
		);
	}

	renderActive() {
		const { classes, onClick, buttonText, variant } = this.props;
		return (
			<Button onClick={onClick} variant={variant} color="primary" className={classes.submitButton}>
				{buttonText}
			</Button>
		);
	}

	renderDisabled() {
		const { classes, buttonText, variant, disabledText = 'Write access is disabled'} = this.props;
		return (
			<Tooltip
				title={disabledText}
				enterDelay={300}
			>
				<div style={{display:'inline-block'}}>
					<Button variant={variant} color="primary" className={classes.submitButton} disabled>
						{buttonText}
					</Button>
				</div>
			</Tooltip>
		);
	}
}

FormSubmitButton.propTypes = {
	onClick : PropTypes.func.isRequired,
	buttonText : PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	isDisabled : PropTypes.bool,
	disabledText : PropTypes.string,
	variant : PropTypes.string,
};

FormSubmitButton.defaultProps = {
	variant : 'contained',
};
export default _.flow([
	withStyles(styles),
])(FormSubmitButton);
