import _ from 'lodash';
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { getGlobalClasses } from '../themes';
import genericPlusIcon from "../images/icons/genericPlus.svg";
import {Link} from "react-router-dom";

const styles = (theme) => {
	return {
		...getGlobalClasses(theme),
		pickerCardPaper : {
			height : '100%',
		},
		pickerCardPadding : {
			padding : 30,
		},
		pickCardTitleContainer : {
			paddingTop : 10,
			display : 'flex',
			alignContent : 'center',
			alignItems: 'center',
			paddingBottom : 30,
		},
		pickerCardTitle : {
			paddingLeft : 10,
			fontSize : 28,
			color : '#333',
		},
		icon : {
			width : 50,
		},
		pickerCardDescriptionLabel : {
			textTransform : 'uppercase',
			color : '#999',
			fontSize : 16,
		},
		pickerCardDescription : {
			fontSize : 18,
		},
		pickerCardButtonContainer : {
			textAlign : 'right',
			paddingTop : 30,
		},
		pickerCardButton : {
			paddingRight : 55,
			paddingLeft : 55,
			fontSize : 18,
		},

	};
};


const PickerCard = props => {
	const {
		classes,
		title,
		description,
		url,
		icon,
		disabled
	} = props;
	return (
		<Paper className={classes.pickerCardPaper}>
			<div className={classes.pickerCardPadding}>
				<div className={classes.pickCardTitleContainer}>
					<img
						alt="Action Icon"
						src={icon}
						className={classes.icon}
					/>
					<span className={classes.pickerCardTitle}>{title}</span>
				</div>
				<div>
					<div className={classes.pickerCardDescriptionLabel}>
						Description
					</div>
					<div className={classes.pickerCardDescription}>
						{description}
					</div>

					<div className={classes.pickerCardButtonContainer}>
						<Link to={url} style={{ textDecoration: 'none' }}>
							<Button
								variant="contained"
								color="primary"
								aria-label="Add"
								size="large"
								className={classes.pickerCardButton}
								disabled={disabled}
							>
								Start
							</Button>
						</Link>
					</div>
				</div>
			</div>
		</Paper>
	);
};

class Administration extends Component {
	render () {
		const {
			classes,
		} = this.props;

		return (<Fragment>
			<div className={classes.globalMainHeadline}>Company Administration</div>

			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<PickerCard
						classes={classes}
						url={'/user/add'}
						title={'Add New User'}
						icon={genericPlusIcon}
						description={`Setup new users in PUMP.`}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<PickerCard
						classes={classes}
						title={'Future Setting'}
						icon={genericPlusIcon}

						url={'/administration'}
						disabled={true}
						description={
							`Placeholder for future administrator settings`
						}
					/>
				</Grid>
			</Grid>

		</Fragment>);
	}
}

Administration.propTypes = {
	classes: PropTypes.object.isRequired,
	authUser : PropTypes.object,
};

export default _.flow([
	withStyles(styles),
])(Administration);
