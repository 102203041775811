import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AuthUserContainer from '../containers/AuthUser';

import { withStyles } from '@material-ui/core/styles/index';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//- icons
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventsIcon from '@material-ui/icons/DateRange';
import EmailIcon from '@material-ui/icons/Email';
import NotificationIcon from '@material-ui/icons/NotificationsActive';
import UsersIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/ExitToApp';


import { MuiThemeProvider } from '@material-ui/core/styles';
import { darkTheme } from '../themes';
import { Link } from 'react-router-dom';
import { Route }  from 'react-router-dom';
import logoImage from "../images/logos/pumpLogo.svg";

const styles = () => {
	return {
		icon: {
			margin: 0,
		},
		iconText: {
			paddingLeft: 8,
			paddingTop: 2,
			color : 'white',
		},
		selected: {
			backgroundColor: '#2b95f3',
			height: '3px',
			margin: '0px 16px',
			top: '-4px',
			position: 'relative',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		},
		nested: {
			paddingLeft: 18,
			marginTop: -10,
			marginBottom: -10,
		},
		nestedRoot: {
			paddingLeft: 5,
			color : '#fff',
		},
		nestedText: {
			fontWeight: 100,
			color : '#fff',
		},
		nestedIcon: {
			borderBottom: '1px solid white',
			borderLeft: '1px solid white',
			margin: 0,
			position: 'relative',
			opacity: 0.4,
			top: -10,
			left: -7,
			width: 18,
			height: 18,
		},
		tightIcon : {
			marginRight : 5,
			minWidth : 'auto',
		},
		logoContainer : {
			width : '100%',
			paddingTop : 20,
			paddingLeft: 15,
			paddingRight : 15,
			boxSizing : 'border-box',
		},
		logo : {
			width : '100%'
		},
		logoSubText: {
			paddingTop: 5,
			letterSpacing: '-.1px',
			color: '#eee',
			fontWeight: 200,
			textTransform: 'uppercase',
			fontSize: 11,
			whiteSpace : 'noWrap',
			paddingBottom : 20,
		},
		loginContainer : {
			paddingTop : 30,
			paddingLeft : 18,
			paddingBottom : 3,
		},
		loginLabel : {
			color : '#999',
			fontSize : 13,
			textTransform: 'uppercase',
		},
		loginUserName : {
			color : '#eee',
		},
	};
};

class LeftDrawer extends Component {

	constructor (props) {
		super(props);
		this.state = {
			open : true,
		};
	}

	handleLogout = () => {
		this.props.logout();
	};

	render () {
		const {authUser, drawerOpen, drawerPaper, classes, permissions} = this.props;
		if (!authUser) { return null; }

		const menuStruct = [{
			link: '/dashboard',
			exact: true,
			icon: <DashboardIcon className={classes.icon}/>,
			label: 'Dashboard',
		}, {
			link: '/newsItem/list',
			exact: true,
			icon: <EventsIcon className={classes.icon}/>,
			label: 'Event Feed',
		}, {
			link: '/notification/list',
			exact: true,
			icon: <NotificationIcon className={classes.icon}/>,
			label: 'Notifications',
		}, {
			link: '/customerEmails',
			exact: true,
			icon: <EmailIcon className={classes.icon}/>,
			label: 'Customer Emails',
		}];

		const permissionMap = (permissions) ? _.keyBy(permissions) : {};
		const isAdmin = _.some(['canAddAndUpdateUsers'], s => {
			return (permissionMap[s]);
		});

		if (isAdmin) {
			menuStruct.push({
				showDivider: true,
			}, {
				link: '/administration',
				router: '/administration',
				icon: <SettingsIcon className={classes.icon}/>,
				label: 'Administration',
			});
		}
		/** @namespace permissionMap.canAddAndUpdateUsers **/
		if (permissionMap.canAddAndUpdateUsers) {
			menuStruct.push({
				nested: true,
				link: '/user/list',
				route: '/user/*',
				icon: <UsersIcon className={classes.icon}/>,
				label: 'Users',
			});
		}

		/** @namespace authUser.firstName **/
		/** @namespace authUser.lastName **/

		return (
			<MuiThemeProvider theme={darkTheme}>
				<Drawer
					variant="persistent"
					open={drawerOpen}
					classes={{ paper: drawerPaper }}
				>
					<Toolbar style={{backgroundColor: 'none'}}>
						<div className={classes.logoContainer}>
							<img
								alt="Logo"
								src={logoImage}
								className={classes.logo}
							/>
						</div>
					</Toolbar>


					<List style={{textAlign: "left", paddingTop : 20, paddingBottom : 20}}>
						{menuStruct.map((menu, i) => {

							const { showDivider, nested, route } = menu;

							if (showDivider) {
								return (<Divider key={`divider${i}`} style={{marginTop:20, marginBottom : 20}} />);
							}

							if (!nested) {
								return (
									<Link key={i} to={menu.link} style={{ textDecoration: 'none' }}>
										<ListItem button>
											<ListItemIcon classes={{ root : classes.tightIcon}}>
												{menu.icon}
											</ListItemIcon>
											<ListItemText primary={menu.label} className={classes.iconText}/>
										</ListItem>
										<Route
											exact={menu.exact}
											path={(route) ? route : menu.link}
											render={() => <div className={classes.selected}/>}
										/>
										{menu.link === '/dashboard' &&
											<Route
												exact={menu.exact}
												path={'/'}
												render={() => <div className={classes.selected}/>}
											/>
										}
									</Link>
								);
							} else {
								return (
									<div key={i} className={classes.nested}>
										<Link key={i} to={menu.link} style={{ textDecoration: 'none' }}>
											<ListItem button>
												<ListItemIcon classes={{ root : classes.tightIcon}}>
													<div className={classes.nestedIcon} />
												</ListItemIcon>
												<ListItemText
													primary={menu.label}
													classes={{root : classes.nestedRoot, primary : classes.nestedText}}
												/>
											</ListItem>
											<Route
												path={(route) ? route : menu.link}
												render={() => <div className={classes.selected}/>}
											/>
										</Link>
									</div>
								);
							}
						})}
					</List>
					<Divider/>

					<List style={{textAlign: "left" }}>
						<div className={classes.loginContainer}>
							<div className={classes.loginLabel}>Logged in as</div>
							<div className={classes.loginUserName}>
								{authUser.firstName} {authUser.lastName}
								</div>
						</div>

						<Link
							to={'/logout'}
							onClick={this.handleLogout}
							style={{ color: 'white', textDecoration: 'none' }}
						>
							<ListItem button>
								<ListItemIcon>
									<LogoutIcon className={classes.icon}/>
								</ListItemIcon>

								<ListItemText primary="Logout"/>
							</ListItem>
						</Link>
					</List>
				</Drawer>
			</MuiThemeProvider>
		);
	}
}

LeftDrawer.propTypes = {
	classes : PropTypes.object,
	drawerOpen : PropTypes.bool,
	drawerPaper : PropTypes.string,
	logout : PropTypes.func,
	permissions : PropTypes.array,
	authUser : PropTypes.object,
};

export default _.flow([
	withStyles(styles),
	AuthUserContainer,
])(LeftDrawer);
