import _ from 'lodash';
import React, {Component } from 'react';
import PropTypes from 'prop-types';
import Map from './Map';

// material ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';

//- components / form
import FormSubmitButton from '../../components/form/FormSubmitButton';
import FormText from '../../components/form/FormText';
import FormTextArea from '../../components/form/FormTextArea';
import SectionHeader from "../../components/SectionHeader";
import StationPicker from "../../components/advancedForm/StationPicker";
import { post } from "../../modules/utility";
//import FormContainer from "../../components/FormContainer";

// containers
import AuthUserContainer from '../../containers/AuthUser';
import NotificationContainer from '../../containers/Notification';
import Paper from "@material-ui/core/Paper";
import {getGlobalClasses} from "../../themes";
import FormCheckbox from "../../components/form/FormCheckbox";

//- icons
import flashSaleSvg from '../../images/icons/flashSaleLightningBolt.svg';
import grandOpeningSvg from '../../images/icons/grandOpeningNewBurst.svg';
import comingSoonSvg from '../../images/icons/comingSoonCalendar.svg';
import StationContainer from "../../containers/Station";
import AreYouSureDialog from "../../components/dialogs/AreYouSureDialog";

const MAX_DISTANCE = 100;

const styles = (theme) => {
	return {
		...getGlobalClasses(theme),
		typeTitle : {
			display : 'flex',
			alignItems : 'center',
			justifyContent : 'center',
		},
		typeCheckbox : {
			display : 'flex',
			alignItems : 'center',
			justifyContent : 'center',
		},
		typeIcon : {
			display : 'inline-block',
			width : 49,
			height : 55,
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			verticalAlign: 'middle',
			cursor : 'pointer',
		},
		flashSaleIcon: {
			backgroundImage: `url('${flashSaleSvg}')`,
			width : 49,
			height : 55,
		},
		grandOpeningIcon: {
			backgroundImage: `url('${grandOpeningSvg}')`,
			width : 58,
			height : 58,
		},
		comingSoonIcon: {
			backgroundImage: `url('${comingSoonSvg}')`,
			width : 54,
			height : 54,
		},
	};
};

class SaveNotification extends Component {
	constructor(props) {
		super(props);

		const action = _.get(this.props, 'match.params.action');
		const notificationId = parseInt(_.get(this.props, 'match.params.notificationId'), 10);
		const isUpdate = (action === 'update');

		this.state = {
			isUpdate,
			notificationId,
			formFieldError: {},
			permissionMap : {},
			testCustomerIds: [],
			//- TODO comment tout
			// type : 'flashSale',
			// title : 'E85 Flash Sale ⚡️',
			// message : 'Sacramento - Mobile Station',
			// dateTimeLine : 'May 14th 9am - Closing',

			//- default center is pearson fuels
			mapCenter : {
				lat: 32.72480751180992,
				lng: -117.22946187220765,
			},
			circleCenter : {
				lat: 32.72480751180992,
				lng: -117.22946187220765,
			},
			distance : 10,
			customers : [],
			flashSale : false,
			comingSoon : false,
			grandOpening : false,

			isAreYouSureDialogOpen : false,
			currentType : undefined,

		};
	}

	componentDidMount () {
		const {notification, getNotification, stations} = this.props;
		const {notificationId, isUpdate,} = this.state;

		this.getTestCustomerIds();
		if (!_.isEmpty(stations)) {
			const stationMap = _.keyBy(stations, 'stationId');
			this.setState({stationMap});
		}

		if (!isUpdate) {
			this.searchCustomers();
			return;
		}

		if (notification) {
			this.fillForm({notification});
		} else {
			getNotification({
				params: {notificationIds: [notificationId]}
			});
		}

	}

	componentDidUpdate(prevProps, prevState, snapShot) {
		const { notification, stations } = this.props;
		let { stationMap, destinationStationId} = this.state;

		const newState = {};

		if (_.isUndefined(prevProps.notification) && !_.isUndefined(notification)) {
			this.fillForm({ notification });
		}

		if (prevProps.authUser !== this.props.authUser) {
			this.getTestCustomerIds()
		}

		if (
			this.state.distance !== prevState.distance
			|| this.state.circleCenter.lat !== prevState.circleCenter.lat
			|| this.state.circleCenter.lng !== prevState.circleCenter.lng
		) {
			this.searchCustomers();
		}

		if (_.isEmpty(prevProps.stations) && !_.isEmpty(stations)) {
			stationMap = _.keyBy(stations, 'stationId');
			newState.stationMap = stationMap;
		}

		if (prevState.destinationStationId !== destinationStationId) {
			if (stationMap) {
				const station = stationMap[destinationStationId] || {};
				if (station.location) {
					newState.mapCenter = {
						lat: station.location.latitude,
						lng: station.location.longitude
					};
					newState.circleCenter = {
						lat: station.location.latitude,
						lng: station.location.longitude
					};

				}
			}
		}

		if (!_.isEmpty(newState)) {
			this.setState(newState);
		}
	}

	getTestCustomerIds () {
		const { authUser } = this.props;
		const email = _.get(authUser, 'email');

		if (!email) {
			return;
		}

		post({
			method: 'user.customer.search',
			params: {
				email
			},
			callback: (err, r) => {
				if (err) { return console.error(err); }
				const customerId = _.get(r, 'collections.customerCollection.0.customerId');

				if (customerId) {
					this.setState({
						testCustomerIds: [customerId]
					});
				}
			}
		})
	}

	fillForm = ({ notification }) => {
		if (!notification) { return; }

		const newState = {};
		[
			'type',
			'title',
			'message',
			'dateTimeLine',
			`startDate`,
			'endDate',
			'destinationStationId',
		].forEach(key => {
			if (_.isNil(notification[key])) { return; }
			newState[key] = notification[key];
		});

		this.setState(newState);
	};

	searchCustomers = () => {

		const { searchCustomers, showErrors} = this.props;
		const { circleCenter } = this.state;

		let distance = parseInt(this.state.distance, 10);
		if (!distance || distance <= 2) { return; }
		if (distance >= MAX_DISTANCE) {
			distance = MAX_DISTANCE;
		}

		searchCustomers({
			params : {
				location : {
					latitude : circleCenter.lat,
					longitude : circleCenter.lng,
				},
				distance,
				hasPushDeviceId : true,
			},
			callback : (err, r) => {
				if (err) {
					showErrors(err);
					return;
				}

				const customers = _.get(r, 'collections.customerCollection');
				if (!customers) {
					showErrors('Customer collection is undefined');
					return;
				}

				this.setState({ customers });
			}
		})
	};


	isFormValid = () => {
		const {
			showErrors,
		} = this.props;

		const formFieldError = {};
		const requiredFields = [
			{path: 'distance', label: 'Distance'},
			{path: 'message', label: 'Message'},
			{path: 'destinationStationId', label: 'Station'},
		];

		const types = ['flashSale', 'comingSoon', 'grandOpening'];
		let hasType = false;
		types.forEach(type => {
			if (this.state[type] === true) {
				hasType = true;
			}
		});

		if (!hasType) {
			requiredFields.push(
				{path: 'flashSale', label: 'Please pick a type'},
			);
		}

		requiredFields.forEach(({path, label})=> {
			if (!this.state[path]) {
				formFieldError[path] = `${label} is required`;
			}
		});

		//- Get all the values and do individual checks
		if (_.keys(formFieldError).length) {
			this.setState({formFieldError});
			showErrors(_.values(formFieldError));
			return false;
		} else {
			return true;
		}
	};


	handleTest = () => {
		const customerIds = this.state.testCustomerIds;
		this.submit({ customerIds })
	};

	closeAreYouSureDialog = () => {
		this.setState({ isAreYouSureDialogOpen : false });
	};

	handleAreYouSure = () => {
		if (!this.isFormValid()) {
			return;
		}
		this.setState({ isAreYouSureDialogOpen : true });
	};

	handleSave = () => {
		const {
			customers
		} = this.state;
		const {
			showErrors
		} = this.props;


		if (_.isEmpty(customers)) {
			return showErrors(['You must have at least one customer to send a notification']);
		}

		this.submit({
			customerIds: _.map(customers, 'customerId')
		})
	};

	submit ({ customerIds }) {
		const {
			addNotification,
			updateNotification,
			showErrors,
		} = this.props;

		const {isUpdate, notificationId} = this.state;

		const formFieldError = {};

		const requiredFields = [
			{path: 'distance', label: 'Distance'},
			{path: 'message', label: 'Message'},
			{path: 'destinationStationId', label: 'Station'},
		];

		const types = ['flashSale', 'comingSoon', 'grandOpening'];
		let hasType = false;
		types.forEach(type => {
			if (this.state[type] === true) {
				hasType = true;
			}
		});

		if (!hasType) {
			requiredFields.push(
				{path: 'flashSale', label: 'Please pick a type'},
			);
		}

		requiredFields.forEach(({path, label})=> {
			if (!this.state[path]) {
				formFieldError[path] = `${label} is required`;
			}
		});

		//- Get all the values and do individual checks
		if (_.keys(formFieldError).length) {
			this.setState({formFieldError});
			showErrors(_.values(formFieldError));
			return;
		}

		const {
			distance,
			message,
			destinationStationId,
			circleCenter,
		} = this.state;

		const params = {
			customerIds,
			distance: parseInt(distance, 10),
			message,
			destinationStationId,
			location : {
				latitude : circleCenter.lat,
				longitude : circleCenter.lng,
			}
		};

		types.forEach(type => {
			if (this.state[type]) { params.type = type;}
		});

		this.setState({
			isSavingOpen: true,
			savingStatus : 'saving'
		});

		if (!isUpdate) {
			addNotification({
				params,
			});
		} else {
			params.notificationId = notificationId;
			updateNotification({
				params,
			});
		}
	};

	//- Path is always a string
	//- value is never an event (value.target)
	onChange = (value, path) => {
		const arrayPath = _.toPath(path);
		const basePath = _.head(arrayPath);
		let base = this.state[basePath];
		let newState = {};


		const types = ['flashSale', 'comingSoon', 'grandOpening'];
		if (_.includes(types, path)) {
			types.forEach(type => {
				newState[type] = false;
			});
			if (value === true) {
				newState.currentType = path;
			}
		}

		if (_.isArray(base) || _.isObject(base)) {
			base = _.cloneDeep(base);
			_.set(base, arrayPath.slice(1), value);
			newState[basePath] = base;
		} else {
			newState[basePath] = value;
		}

		//- Clear any errors on the path
		let formFieldError = Object.assign({}, this.state.formFieldError);
		delete formFieldError[path];
		newState.formFieldError = formFieldError;


		this.setState(newState);
	};

	getInputProps = (path, defaultValue) => {
		return {
			key : path,
			path,
			value  : this.getValue(path, defaultValue),
			checked  : this.getValue(path, defaultValue),
			error : this.getError(path),
			onChange : this.onChange,
			gridProps : { md : 12 },
		};
	};

	getValue  = (path, defaultValue) => {
		const _d = (_.isUndefined(defaultValue)) ? '' : defaultValue;
		return _.get(this.state, path, _d);
	};

	getError = (path) => {
		return _.get(this.state.formFieldError, path);
	};

	onDragStart = () => {
		this.setState({ customers : [] });
	};
	onDragEnd = (circleCenter) => {
		this.setState({ circleCenter });
	};
	onMapDragEnd = (mapCenter) => {
		this.setState({mapCenter});
	};

	render () {
		const {
			classes,
			authUser,
		} = this.props;

		const {
			isUpdate,
			//type,
			customers,
			distance,
			mapCenter,
			circleCenter,
			testCustomerIds,
		} = this.state;

		let radiusDistance = parseInt(distance);
		const action = (isUpdate) ? 'Update' : 'Add';

		let isRadiusDistanceOverMax = false;
		if (radiusDistance > MAX_DISTANCE) {
			radiusDistance = MAX_DISTANCE;
			isRadiusDistanceOverMax = true;
		}

		return (<Paper className={classes.globalMainPaper}>
			<form>
				<div className={classes.globalMainHeadline}>Send Notification</div>
				<Grid container spacing={2}>
					<Grid item xs={12}>

						<StationPicker
							{...this.getInputProps('destinationStationId')}
							label={'Station (destination when event is clicked)'}
							helpText={'Will center map on lon/lat'}
						/>

						<div style={{marginBottom:20}}/>
						<Map
							mapCenter={mapCenter}
							circleCenter={circleCenter}
							zoom={11}
							customers={customers}
							radiusDistance={radiusDistance}
							googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBjbDdlqMJZU7VmhRCNf-Wg9D8v985I8Dc"
							loadingElement={<div style={{ height: `700px` }} />}
							containerElement={<div style={{ height: `700px`, width : '100%' }} />}
							mapElement={<div style={{ height: `100%` }} />}
							onDragStart={this.onDragStart}
							onDragEnd={this.onDragEnd}
							onMapDragEnd={this.onMapDragEnd}
						/>
					</Grid>

					<Grid item xs={12}>
						<div style={{marginBottom : 20, fontSize : '150%' }}>
							<span style={{ fontWeight : 800}}>{customers.length} </span>
							total customers with enabled notifications in this area
						</div>

						{isRadiusDistanceOverMax &&
						<div style={{marginBottom : 20, fontSize : '100%', color : 'red' }}>
							Radius distance is max {MAX_DISTANCE} miles
						</div>
						}

					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={10} md={10} lg={8}>
						<Grid container spacing={2}>
							<FormText
								{...this.getInputProps('distance')}
								label={'Radius distance (in miles)'}
							/>

							<SectionHeader label='Notification options' gridProps={{md : 12 }} />

							<Grid item xs={12}>
								<div style={{
									lineHeight : 1.8,
									fontSize : '110%',
									marginBottom : 20, border : '1px solid #ddd',
									backgroundColor : '#f5f5f5',
									padding : 20,
								}}>
									<strong>Notification tips</strong>
									<span style={{color:'#666', fontSize : '90%'}}> (* from ComScore data)</span><br/>
									- A good push notification should be less than 40 to 50 characters<br/>
									- Best timing is during peak mobile usage which is between 6 pm to 10 pm<br/>
								</div>
							</Grid>

							<Grid item xs={4}>
								<div className={classes.typeTitle}>
									<div
										className={classNames(classes.typeIcon, classes.flashSaleIcon)}
										onClick={() => { this.onChange(true, 'flashSale');}}
									/>
								</div>
								<div className={classes.typeCheckbox}>
									<FormCheckbox
										{...this.getInputProps('flashSale')}
										label={'Sale'}
										gridProps={null}
									/>
								</div>
							</Grid>

							<Grid item xs={4}>
								<div className={classes.typeTitle}>
									<div
										className={classNames(classes.typeIcon, classes.grandOpeningIcon)}
										onClick={() => { this.onChange(true, 'grandOpening');}}
									/>
								</div>
								<div className={classes.typeCheckbox}>
									<FormCheckbox
										{...this.getInputProps('grandOpening')}
										label={'Now Open'}
										gridProps={null}
									/>
								</div>
							</Grid>
							<Grid item xs={4}>
								<div className={classes.typeTitle}>
									<div
										className={classNames(classes.typeIcon, classes.comingSoonIcon)}
										onClick={() => { this.onChange(true, 'comingSoon');}}
									/>
								</div>
								<div className={classes.typeCheckbox}>
									<FormCheckbox
										{...this.getInputProps('comingSoon')}
										label={'Coming Soon'}
										gridProps={null}
									/>
								</div>
							</Grid>

							<FormTextArea
								{...this.getInputProps('message')}
								label={'Message'}
								rows={3}
							/>

							{!isUpdate &&
							<div style={{ display : 'flex'}}>

								<FormSubmitButton
									onClick={this.handleTest}
									buttonText={`TEST : Send just to me`}
									variant={'contained'}
									disabledText={
										`Login to PUMP with the same email address ` +
										`(${_.get(authUser, 'email')}) as a customer account on ` +
										`the phone to test functionality.`
									}
									isDisabled={_.isEmpty(testCustomerIds)}
								/>

								<div style={{ width : 15}} />

								<FormSubmitButton
									onClick={this.handleAreYouSure}
									buttonText={`Send notification to customers`}
								/>
							</div>
							}

							{isUpdate &&
							<FormSubmitButton
								onClick={_.noop}
								buttonText={`${action} Notification`}
								isDisabled={true}
								disabledText={`You can't update a notification that has already been sent`}
							/>
							}

						</Grid>
					</Grid>
				</Grid>
			</form>

			<AreYouSureDialog
				open={this.state.isAreYouSureDialogOpen}
				onClose={this.closeAreYouSureDialog}
				totalCustomers={customers.length}
				message={this.state.message}
				handleSave={this.handleSave}
				currentType={this.state.currentType}
			/>

		</Paper>);
	}
}

SaveNotification.propTypes = {
	addNotification : PropTypes.func.isRequired,
	updateNotification : PropTypes.func.isRequired,
	searchCustomers : PropTypes.func.isRequired,
	notification : PropTypes.object,
	searchStations : PropTypes.func.isRequired,
	stations : PropTypes.array,

	showErrors : PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default _.flow([
	withStyles(styles),
	AuthUserContainer,
	NotificationContainer,
	StationContainer,
])(SaveNotification);
