import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import goodJobSvg from '../../images/icons/goodJob.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
	goodJobIcon: {
		display : 'inline-block',
		width : 50,
		height : 50,
		backgroundImage: `url('${goodJobSvg}')`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		verticalAlign: 'middle',
	},
	title : {
		fontSize : 30,
		display: 'inline-block',
		verticalAlign: 'middle',
		paddingLeft: 5,
		paddingTop: 5,
	},
	inner : {
		padding : '10px 20px',
	}
};

export default class SavingDialog extends Component {
	render() {
		const {
			status, processingText, successText,
			open, onClose, onContinue,
		} = this.props;

		return (
			<div>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					onClose={onClose}
				>
					<div style={styles.inner}>
						<DialogTitle>
							<div>
								{status !== 'done' &&
								<div style={{display : 'flex', alignItems : 'center'}}>
									<CircularProgress />
									<span style={styles.title}>Saving...</span>
								</div>
								}

								{status === 'done' &&
								<div>
									<div style={styles.goodJobIcon}/>
									<span style={styles.title}>Success!</span>
								</div>
								}
							</div>
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{status !== 'done' && processingText}
								{status === 'done' && successText}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								disabled={status !== 'done'}
								onClick={onContinue}
								variant={"outlined"}
								color="primary"
							>
								Continue
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}
}

SavingDialog.propTypes = {
	status : PropTypes.string,
	open : PropTypes.bool,
	onClose : PropTypes.func,
	onContinue : PropTypes.func,
	processingText : PropTypes.string,
	successText : PropTypes.string,
};

SavingDialog.defaultProps = {
	processingText : 'Processing...',
	successText : 'Save was successful'
};
