import { combineReducers } from 'redux';
import auth from './auth';
import maps from './maps';
import lists from './lists';
import general from './general';
import { connectRouter } from 'connected-react-router';

//import user from './user';

export default (history) => combineReducers({
	router: connectRouter(history),
	auth,
	general,
	lists,
	maps,
});