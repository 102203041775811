import _ from 'lodash';

export const sortTable = ({ rows, order, orderBy }) => {
	return (order === 'desc')
		? rows.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
		: rows.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
};

export const isFiltered = ({filter, row, fields }) => {
	if (!filter) { return false; }
	const cleanSearch = filter.replace(/[^a-z0-9]/gi,'');
	const re = new RegExp('\\b' + cleanSearch, 'gi');
	return !_.some(fields, field => {
		return re.test(row[field]);
	});
};
