import _ from "lodash";

import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";

import LoginContainer from '../containers/Login';
import logoImage from "../images/logos/pumpLogo.svg";
//import withWidth from "@material-ui/core/withWidth";

import loginBackground from '../images/waveBackground.jpg';

const styles = theme => {
	return {
		background: {
			position: 'absolute',
			zIndex: -1,
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		},
		bgPhoto: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			backgroundImage : `url('${loginBackground}')`,
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			opacity: .2,
		},
		bgColorOverlay: {
			width: '100%',
			height: '100%',
			backgroundColor: theme.palette.primary.main,
			backgroundImage: 'linear-gradient(-180deg, #2BB5E3 0%, #137EC2 100%)',
		},
		container: {
			margin: 0,
			width: '100%',
			height: '100%',
			position: "absolute",
		},
		cardPadding : {
			padding : '40px 40px',
			marginBottom: '20%',
			boxShadow: '0 0 2px 0 #000000, 0 4px 173px 0 rgba(0,0,0,0.40)',

			[theme.breakpoints.up('sm')]: {
				padding : '60px 70px',
			},
			[theme.breakpoints.up('sm')]: {
				padding : '80px 90px',
			}
		},
		logo : {
			width : 250,
			[theme.breakpoints.up('sm')]: {
				width : 320,
			},

		},
		logoSubText: {
			paddingTop: 5,
			letterSpacing: '.8px',
			color: '#333',
			fontWeight: 200,
			textTransform: 'uppercase',
			fontSize: 12,
			whiteSpace : 'noWrap',
			paddingBottom : 20,
			[theme.breakpoints.up('sm')]: {
				fontSize : 15,
				paddingBottom : 30,
			},
			[theme.breakpoints.up('md')]: {
				paddingBottom : 40,
			}

		},
		loginButtonContainer: {
			paddingTop: 20,

		},
		authMessages: {
			minHeight: 50,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
		},
		loginButton: {
			fontSize: 18,
			borderRadius: '100px',
			padding: '15px 40px',
		},
		screenCenter: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100%',
		}
	};
};

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email : '',
			password: '',
			redirectToReferrer: false,
			formFieldError : {},
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.props.authSuccess && prevProps.authSuccess) {
			this.setState({
				redirectToReferrer : true
			});
		}
	}

	loginClicked = () => {

		//- Required fields
		const formFieldError = {};
		[
			{ path : 'email', label : 'Email' },
			{ path : 'password', label : 'Password'},
		].forEach(({ path, label }) => {
			if (!this.state[path]) {
				formFieldError[path] = `${label} is required`;
			}
		});

		//- Get all the values and do individual checks
		if (_.keys(formFieldError).length) {
			this.setState({formFieldError});
			return;
		}

		const { email, password } = this.state;
		const params = { password, email };
		this.props.login(params);
	};

	onChangePassword = (e) => {
		const newState = { password: e.target.value };

		//- Clear any errors on the path
		let formFieldError = Object.assign({}, this.state.formFieldError);
		delete formFieldError.password;
		newState.formFieldError = formFieldError;
		this.setState(newState);

	};

	onChangeEmail = (e)  => {
		const newState = { email : e.target.value};

		//- Clear any errors on the path
		let formFieldError = Object.assign({}, this.state.formFieldError);
		delete formFieldError.email;
		newState.formFieldError = formFieldError;
		this.setState(newState);
	};

	render() {
		const {
			classes,
			accessToken,
			isAuthenticating,
			authSuccess,
			authFailure,
			authErrors,
		} = this.props;

		let formErrors = _.values(this.state.formFieldError);
		if (this.state.redirectToReferrer) {
			return (
				<Redirect to={'/dashboard'} />
			);
		}

		const disableLogin = (isAuthenticating);

		return (
			<Grid container justify={'center'} align={'center'} className={classes.container}>

				{accessToken &&	<Redirect to="/dashboard"/>}

				<div className={classes.background}>
					<div className={classes.bgColorOverlay} />
					<div className={classes.bgPhoto} />
				</div>

				<div>
					<div className={classes.screenCenter}>
						<Card className={classes.cardPadding}>
							<div>
								<img
									alt="Logo"
									src={logoImage}
									className={classes.logo}
								/>
								<div className={classes.logoSubText}>Pearson User Management System</div>
							</div>

							<form>
								<Grid item xs={12} style={{textAlign : 'left'}}>
									<TextField
										autoComplete="email"
										value={this.state.email}
										onChange={this.onChangeEmail}
										label="Email"
										fullWidth
									/>
								</Grid>

								<Grid item xs={12} style={{textAlign : 'left'}}>
									<TextField
										value={this.state.password}
										onChange={this.onChangePassword}
										id="password"
										label="Password"
										type="password"
										autoComplete="current-password"
										margin="normal"
										fullWidth
									/>
								</Grid>

								<Grid item xs={12} className={classes.loginButtonContainer}>
									<div className={classes.authMessages}>
										{authSuccess && <div>Success</div>}
										{authFailure && authErrors.map((authError,i) => {
											return (<div key={i}>{authError}</div>);
										})}
										{!_.isEmpty(formErrors) && formErrors.map((authError,i) => {
											return (<div key={i}>{authError}</div>);
										})}
										{isAuthenticating && <div>Authenticating....</div>}
									</div>
									<Button
										variant="contained"
										disabled={disableLogin}
										className={classes.loginButton}
										color="primary"
										onClick={this.loginClicked}
									>
										LOGIN
									</Button>
								</Grid>
							</form>
						</Card>
					</div>
				</div>
			</Grid>
		);
	}
}

Login.propTypes = {
	accessToken : PropTypes.string,
	isAuthenticating : PropTypes.bool,
	authFailure : PropTypes.bool,
	authSuccess : PropTypes.bool,
	authErrors : PropTypes.array,
	login : PropTypes.func,
};

export default _.flow([
	withStyles(styles, { withTheme: true }),
	//withWidth(),
	LoginContainer,
])(Login);