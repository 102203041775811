import React, { Component, Fragment } from "react";
//import _ from 'lodash';
import {
	withGoogleMap,
	GoogleMap,
	withScriptjs,
	Marker,
	Circle
} from "react-google-maps";
import PropTypes from "prop-types";
//import _ from "lodash";

function getMeters(i) {
	return i*1609.344;
}

class Map extends Component {
	constructor(props) {
		super(props);

		const { circleCenter } = this.props;

		this.state = {
			isDragging : false,
			draggingCircleCenter : circleCenter,
		};
	}

	/*
	componentDidUpdate(prevProps, prevState, snapShot) {
		const { center } = this.props;

		if (_.isObject(prevProps.center) && _.isObject(center)) {
			if (
				prevProps.center.lat !== center.lat
				|| prevProps.center.lng !== center.lng
			) {
				console.log("NEW CENTER", prevProps.center, center);
				this.setState({
					centerLatitude: center.lat,
					centerLongitude: center.lng,
				});
			}
		}
	}
	*/

	onDrag = (evt) => {
		/** @namespace evt.latLng **/
		if (!evt.latLng) { return; }
		this.setState({
			draggingCircleCenter : {
				lat : evt.latLng.lat(),
				lng : evt.latLng.lng(),
			}
		});
	};

	onDragStart = () => {
		const { circleCenter } = this.props;
		this.props.onDragStart();
		this.setState({
			isDragging : true,
			draggingCircleCenter : circleCenter,
		});
	};

	onDragEnd = (evt) => {
		/** @namespace evt.latLng **/
		if (!evt.latLng) {return;}
		this.setState({
			isDragging: false,
		});
		this.props.onDragEnd({
			lat : evt.latLng.lat(),
			lng : evt.latLng.lng(),
		});
	};

	onMapDragEnd = () => {
		const latLng = this.mapRef.getCenter();
		this.props.onMapDragEnd({
			lat : latLng.lat(),
			lng : latLng.lng(),
		});
	};

	render () {
		const {
			radiusDistance, zoom,
			mapCenter,
			customers
		} = this.props;

		const { isDragging, draggingCircleCenter } = this.state;

		const circleCenter = (isDragging) ?  draggingCircleCenter : this.props.circleCenter;
		//console.log("RICLE CENTER", circleCenter);

		return (<Fragment>
			<GoogleMap
				defaultZoom={zoom}
				center={mapCenter}
				onDragEnd ={this.onMapDragEnd}
				ref={(ref) => {
					this.mapRef = ref;
				}}
			>
				<Marker
					position={circleCenter}
					draggable={true}
					onDragStart={this.onDragStart}
					onDrag={this.onDrag}
					onDragEnd={this.onDragEnd}
					zIndex={10}
				/>

				<Circle
					center={circleCenter}
					radius={getMeters(radiusDistance)}
					options={{
						strokeColor: '#333333',
						strokeWeight : 2,
					}}
					zIndex={9}
				/>

				{customers.map(customer => {
					const {
						location, customerId,
						//pushDeviceId
					} = customer;

					if (!location || !location.longitude) {
						return null;
					}

					return (
						<Marker
							key={customerId}
							position={{
								lat: parseFloat(location.latitude),
								lng: parseFloat(location.longitude),
							}}
							icon={'https://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_blue.png'}
							zIndex={5}
						/>
					);
				})}

			</GoogleMap>
		</Fragment>);
	}
}



Map.propTypes = {
	radiusDistance : PropTypes.number,
	customers : PropTypes.array,
	mapCenter : PropTypes.object,
	circleCenter : PropTypes.object,

	onDragStart : PropTypes.func.isRequired,
	onDragEnd : PropTypes.func.isRequired,
	onMapDragEnd : PropTypes.func.isRequired,
};

export default withScriptjs(withGoogleMap(Map));