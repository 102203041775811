import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiSearch, apiFetch } from '../modules/utility';

const apiBase = 'notification';
const item = 'notification';
const idField = 'notificationId';
const idsField = 'notificationIds';
const onSuccessRedirectTo = `/notification/list`;

const search = (p = {}) => {
	const {callback = _.noop} = p;
	return dispatch => {
		apiSearch({
			base : apiBase,
			dispatch,
			callback,
		});
	};
};

const get = ({params = {}, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.get`,
			params,
			dispatch,
			callback,
		});
	};
};

const add = ({params, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.add`,
			listNameToUpdate: idsField,
			params,
			dispatch,
			savingMessage: `Adding new ${item}...`,
			savedMessage : `New ${item} was created successfully. 
				Click continue to return to the ${item}s page.`,
			onSuccessRedirectTo,
			delay:350,
			callback,
		});
	};
};

const update = ({params, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `${apiBase}.update`,
			params,
			dispatch,
			savingMessage: `Updating ${item}...`,
			savedMessage : `${_.capitalize(item)} updated successfully. 
				Click continue to return to the ${item}s page.`,
			onSuccessRedirectTo,
			delay:350,
			callback,
		});
	};
};

const searchCustomers = ({params = {}, callback = _.noop}) => {
	return dispatch => {
		apiFetch({
			method: `user.customer.search`,
			params,
			dispatch,
			callback,
		});
	};
};


const mapStateToProps = (state, ownProps) => {

	//- Get a single item and the list of items
	const id = (ownProps[idField])
		? ownProps[idField]
		: _.get(ownProps, ['match', 'params', idField]);

	const notification = _.get(state.maps, ['notification', id]);

	let notifications = [];
	if (state.lists[idsField]) {
		notifications = _.map(state.lists[idsField], id => state.maps.notification[id]);
	}

	return {
		notification,
		notifications,
		userMap : state.maps.user,
		stationMap : state.maps.station,
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	getNotification : get,
	addNotification : add,
	updateNotification : update,
	searchNotifications : search,
	searchCustomers,

}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
);
