import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default class ErrorDialog extends Component {
	handleClose = () => {
		this.props.onClose();
	};

	render() {
		const { errorMessages } = this.props;

		if (_.isEmpty(errorMessages)) {
			return null;
		}

		return (
			<div>
				<Dialog
					open={this.props.open}
					TransitionComponent={Transition}
					onClose={this.handleClose}
				>
					<DialogTitle>
						<div style={{color : 'red'}}>
							<ErrorOutline style={{verticalAlign: 'bottom'}}/> Error
						</div>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{errorMessages.map((errorMessage, i) => {
								return (<span key={i}>{errorMessage}<br/></span>);
							})}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Continue
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

ErrorDialog.propTypes = {
	errorMessages : PropTypes.array,
	open : PropTypes.bool,
	onClose : PropTypes.func,
};