//import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { gridMargin } from './general';
import _ from "lodash";

export default class FormTextArea extends PureComponent {
	render () {
		const {
			label, value, onChange, path,
			error, gridProps, helpText, rows, disabled
		} = this.props;

		//let value, onChange, error;
		const hasError = !!error;
		const _onChange = event => onChange(event.target.value, path);

		const content = (
			<FormControl fullWidth={true} error={hasError} disabled={disabled} margin={'dense'}>
				<TextField
					error={hasError}
					disabled={disabled}
					value={value}
					onChange={_onChange}
					label={label}
					multiline
					rows={rows}
					fullWidth
					margin={'dense'}
					variant={'outlined'}
				/>
				{hasError && <FormHelperText>{error}</FormHelperText>}
				{(helpText && !hasError) && <FormHelperText>{helpText}</FormHelperText>}
			</FormControl>
		);

		if (gridProps) {
			_.defaults(gridProps, { xs : 12 });
			return (<Grid item {...gridProps} style={gridMargin}>{content}</Grid>);
		} else {
			return content;
		}
	}
}

FormTextArea.propTypes = {
	label : PropTypes.string.isRequired,
	value :	PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	path: PropTypes.string.isRequired,
	onChange : PropTypes.func.isRequired,

	error : PropTypes.string,
	helpText : PropTypes.string,
	disabled : PropTypes.bool,
	rows : PropTypes.number,
	gridProps : PropTypes.object,
};
