import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {getGlobalClasses} from "../themes";
import SectionHeader from "../components/SectionHeader";
import FormSelect from "../components/form/FormSelect";
import FormDatePicker from "../components/form/FormDatePicker";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AuthUserContainer from "../containers/AuthUser";

const styles = (theme) => ({
	...getGlobalClasses(theme),
	submitButton : {
		marginTop : 30,
		fontSize : 18,
		borderRadius : '100px',
		padding : '20px 40px',
		textTransform: 'uppercase',
		whiteSpace : 'nowrap'
	},
});

class CustomerEmails extends Component {

	constructor(props) {
		super(props);
		this.state = {
			platform : 'all'
		};
	}

	//- Path is always a string
	//- value is never an event (value.target)
	onChange = (value, path) => {
		const arrayPath = _.toPath(path);
		const basePath = _.head(arrayPath);
		let base = this.state[basePath];
		let newState = {};

		if (_.isArray(base) || _.isObject(base)) {
			base = _.cloneDeep(base);
			_.set(base, arrayPath.slice(1), value);
			newState[basePath] = base;
		} else {
			newState[basePath] = value;
		}

		//- Clear any errors on the path
		let formFieldError = Object.assign({}, this.state.formFieldError);
		delete formFieldError[path];
		newState.formFieldError = formFieldError;

		this.setState(newState);
	};


	getInputProps = (path, defaultValue) => {
		return {
			key : path,
			path,
			value  : this.getValue(path, defaultValue),
			checked  : this.getValue(path, defaultValue),
			error : this.getError(path),
			onChange : this.onChange,
			gridProps : { md : 8 },
		};
	};

	getValue  = (path, defaultValue) => {
		const _d = (_.isUndefined(defaultValue)) ? '' : defaultValue;
		return _.get(this.state, path, _d);
	};

	getError = (path) => {
		return _.get(this.state.formFieldError, path);
	};

	render () {
		const {
			classes,
			accessToken,
		} = this.props;

		const { beforeDate, afterDate, platform } = this.state;

		const request = {
			method: 'admin.customer.search',
			params : {
				accessToken,
				downloadCsv : true,
				beforeDate,
				afterDate,
				platform,
			}
		};

		const downloadUrl = `/api?request=${JSON.stringify(request)}`;
		console.log(downloadUrl);

		return (<Fragment>
			<Paper className={classes.globalMainPaper}>
				<div className={classes.globalMainHeadline}>
					Email Export
				</div>

				<Grid container spacing={3}>
					<SectionHeader label='Export Filters' gridProps={{md : 12 }} />

					<FormSelect
						{...this.getInputProps('platform')}
						label={'Platform'}
						options={[
							{ display : 'All', value : 'all' },
							{ display : 'iOS Only', value : 'ios' },
							{ display : 'Android Only', value : 'android' },
						]}
					/>

					<FormDatePicker
						{...this.getInputProps('afterDate')}
						label={'Became customer after date'}
						helpText={'Leave bank for forever'}
					/>

					<FormDatePicker
						{...this.getInputProps('beforeDate')}
						label={'Became customer before date'}
						helpText={'Leave bank for forever'}
					/>

					<Grid item md={8}>
						<a
							href={downloadUrl}
							style={{textDecoration: 'none', paddingRight : 5}}
							target="_blank" rel='noreferrer noopener'
						>
							<Button variant={'contained'} color="primary" className={classes.submitButton}>
								Export CSV List
							</Button>
						</a>
					</Grid>
				</Grid>

			</Paper>
		</Fragment>);
	}
}

CustomerEmails.propTypes = {
	classes: PropTypes.object.isRequired,
	accessToken : PropTypes.string,
};

export default _.flow([
	withStyles(styles),
	AuthUserContainer,
])(CustomerEmails);
